<template>
    <div class="group-wrap">
        <Row v-show="title">
            <Col class="title">{{ title }}</Col>
        </Row>
        <Row :class="{ 'fields-wrap': true, 'top-line': topBorder }">
            <Col
                v-for="item in this.$slots.default"
                :key="item.id"
                :span="item.data.attrs.hidden ? '0' : item.data.attrs.col ? item.data.attrs.col : col"
            >
                <!-- 1. 纯文本显示 -->
                <span
                    v-if="item.data.attrs.type === 'text'"
                    :class="{ text: true, warn: item.data.attrs.warn == false }"
                >
                    {{ item.data.attrs.text }}
                </span>

                <!-- 2. 多行文本显示 -->
                <sv-textarea-field
                    v-else-if="item.data.attrs.type === 'textarea'"
                    :label="item.data.attrs.label"
                    :text="item.data.attrs.text"
                />
                <div
                    v-else-if="item.data.attrs.type === 'link'"
                    :class="{ text: true, link: true }"
                    @click="handleClick"
                >
                    {{ item.data.attrs.text }} >>
                </div>

                <!-- 3.标签显示 -->
                <sv-display-field
                    v-else
                    :labelWidth="labelWidth"
                    :label="item.data.attrs.label"
                    :text="item.data.attrs.text"
                    v-show="!item.data.attrs.hasShow || (item.data.attrs.hasShow && item.data.attrs.show)"
                />
            </Col>
        </Row>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: ''
        },
        column: {
            type: Number,
            default: () => 3
        },
        type: {
            type: String,
            default: 'displayfield'
        },
        topBorder: {
            type: Boolean,
            default: () => true
        },
        propCol: String,
        labelWidth: String
    },
    computed: {
        col() {
            return 24 / this.column;
        }
    },
    methods: {
        handleClick() {
            this.$emit('click');
        }
    }
};
</script>

<style lang="less" scoped>
@import '~@/less/sdk/index';
.group-wrap:first-child {
    &:first-child {
        .top-line {
            border: 0px !important;
        }
        .title {
            margin-top: 10px;
        }
    }
}

.fields-wrap {
    padding: 2px 0;

    .title {
        font-size: 12px;
        font-weight: bold;
        display: inline-block;
        width: 120px;
    }
    .text {
        color: #313131;
        font-size: 12px;
        &.warn {
            color: red;
        }
    }
    .link {
        padding: 7px 0;
        cursor: pointer;
        user-select: none;
        color: @primary-color;
        width: 100%;
    }
}

.top-line {
    border-top: 1px solid @bd-color;
}
</style>
<template>
    <div class="sv-combobox-field" @click.stop>
        <span class="label" :style="{ width: labelWidth }">
            <span v-if="required" class="require-star">*</span>{{ label }}：</span
        >
        <span :class="{ text: true, error: error }">
            <sv-combobox
                ref="combobox"
                :name="name"
                v-model="model"
                :width="width"
                :url="url"
                @onchange="handleChange"
            />
        </span>
    </div>
</template>

<script>
export default {
    data() {
        return { model: this.value, error: false };
    },
    props: {
        name: {
            type: String
        },
        label: {
            type: String
        },
        url: {
            type: String
        },
        width: {
            type: String
        },
        labelWidth: {
            type: String,
            default: '80px'
        },
        value: {
            type: [String, Array, Number]
        },
        required: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        value(value) {
            this.model = value;
        }
    },
    methods: {
        getValue() {
            return this.value;
        },

        handleChange(value, selectionRecord, combobox) {
            this.error = false;
            this.$emit('onchange', value, selectionRecord, combobox);
            this.$emit('input', value);
        },

        isEmptyValue() {
            this.error = true;
            return this.isEmpty(this.model);
        }
    }
};
</script>

<style lang="less" scoped>
@import '~@/less/sdk/index';

.sv-combobox-field {
    display: flex;
    align-items: center;
    font-size: 12px;
    padding: 5px 0;
    line-height: 18px;

    .label {
        display: inline-block;
        line-height: 28px;
        color: @lightest-font-color;
        text-align: right;
        .require-star {
            display: inline-block;
            color: red;
            margin-right: 3px;
        }
    }

    .text {
        flex: 1;
        color: @font-color;
    }
}

/deep/.error .ivu-select-selection {
    border: 1px solid red;
}
</style>

<template>
    <div class="nav-tags">
        <div class="btn-operate btn-operate-close">
            <Dropdown transfer>
                <span class="operate-close-inner">
                    <Icon :size="18" type="ios-close-circle-outline" />
                </span>
                <DropdownMenu slot="list">
                    <DropdownItem name="close-all" @click.native="handleCloseAll">关闭所有</DropdownItem>
                    <DropdownItem name="close-others" @click.native="handleCloseOther">关闭其他</DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </div>
        <ul
            class="context-menu"
            v-show="isShowContextMenu"
            :style="{ left: contextMenuLeft + 'px', top: contextMenuTop + 'px' }">
            <li @click="handleCloseAll">关闭所有</li>
            <li @click="handleCloseOther">关闭其它</li>
        </ul>
        <div class="btn-operate btn-operate-left" @click="handleScroll(240)" v-if="isShowMoveArror">
            <Icon :size="18" type="ios-arrow-back" />
        </div>
        <div class="btn-operate btn-operate-right" @click="handleScroll(-240)" v-if="isShowMoveArror">
            <Icon :size="18" type="ios-arrow-forward" />
        </div>

        <div class="tags-wrap" ref="tagsWrap" @DOMMouseScroll="handleWheel" @mousewheel="handleWheel">
            <div class="tags" ref="tags" :style="{ left: tagsLeft + 'px' }">
                <Tag
                    v-for="item in tagList"
                    :key="item.code"
                    ref="tagItems"
                    :color="item.code === activeTagCode ? 'primary' : 'default'"
                    :closable="item.code !== 'M100-1'"
                    @contextmenu.prevent.native="handleContextMenu(item, $event)"
                    @click.native="handleClickTag(item)"
                    @on-close="handleCloseTag(item.code)">
                    {{ item.name }}
                </Tag>
            </div>
        </div>
    </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import { isIncluded } from '@/common';

export default {
    data: function () {
        return {
            isShowContextMenu: false,
            contextMenuLeft: 0,
            contextMenuTop: 0,
            tagsLeft: 0,
            tagsPadding: 4,
            activeTagCode: 'code18',
            isShowMoveArror: false
        };
    },
    computed: {
        ...mapState('router', ['tagList'])
    },
    methods: {
        handleWheel(e) {
            const type = e.type;
            let delta = 0;
            if (type === 'DOMMouseScroll' || type === 'mousewheel') {
                delta = e.wheelDelta ? e.wheelDelta : -(e.detail || 0) * 40;
            }
            this.handleScroll(delta);
        },

        handleScroll(num) {
            const wrapWidth = this.$refs.tagsWrap.offsetWidth;
            const width = this.$refs.tags.offsetWidth;

            if (num > 0) {
                this.tagsLeft = Math.min(25, this.tagsLeft + num);
            } else {
                if (wrapWidth <= width) {
                    if (this.tagsLeft < -(width - wrapWidth)) {
                        // this.tagsLeft = this.tagsLeft;
                    } else {
                        this.tagsLeft = -Math.max(this.tagsLeft + num, outerWidth - width);
                    }
                } else {
                    this.tagsLeft = 0;
                }
            }
        },

        hideContextMenu() {
            this.isShowContextMenu = false;
        },

        handleContextMenu(item, e) {
            const offsetLeft = this.$el.getBoundingClientRect().left;
            this.isShowContextMenu = true;
            this.contextMenuLeft = e.clientX - offsetLeft + 10;
            this.contextMenuTop = e.clientY - 46;
        },

        scrollIntoView(tag) {
            const wrapWidth = this.$refs.tagsWrap.offsetWidth;
            const width = this.$refs.tags.offsetWidth;

            if (wrapWidth > width) {
                this.tagsLeft = 0;
            } else if (tag.offsetLeft < -this.tagsLeft) {
                // 标签在可视区域左侧
                this.tagsLeft = -tag.offsetLeft + this.tagsPadding;
            } else if (
                tag.offsetLeft > -this.tagsLeft &&
                tag.offsetLeft + tag.offsetWidth < -this.tagsLeft + wrapWidth
            ) {
                // 标签在可视区域
                this.tagsLeft = Math.min(0, wrapWidth - tag.offsetWidth - tag.offsetLeft - this.tagsPadding);
            } else {
                // 标签在可视区域右侧
                this.tagsLeft = -tag.offsetLeft + this.tagsPadding;
            }

            this.handleMoveArror();
        },

        getActiveTag() {
            let activeIndex = 0;
            for (let i = 0; i < this.tagList.length; i++) {
                if (this.tagList[i].code === this.activeTagCode) {
                    activeIndex = i;
                    break;
                }
            }
            if (this.$refs && this.$refs.tagItems && this.$refs.tagItems[activeIndex]) {
                return this.$refs.tagItems[activeIndex].$el;
            } else {
                return null;
            }
        },

        getTagByRoute(route) {
            const isInclude = isIncluded(route.name, this.tagList, 'code');
            if (!isInclude) {
                this.addTag({
                    code: route.name,
                    path: route.path,
                    name: route.meta.title
                });
            }
            this.activeTagCode = route.name;
            const activeTag = this.getActiveTag();
            this.scrollIntoView(activeTag);
        },

        handleCloseAll() {
            const homeItem = this.tagList.filter(item => item.code == 'announcement');
            this.setTagList(homeItem);
            this.handleClickTag(homeItem[0]);
            this.handleMoveArror();
        },

        handleCloseOther() {
            const otherItem = this.tagList.filter(
                item => item.code == 'announcement' || item.code == this.activeTagCode
            );
            this.setTagList(otherItem);
            this.handleMoveArror();
        },

        handleClickTag(item) {
            this.$router.push(`${item.path}`);
            this.$emit('clickTag', item);
        },

        handleCloseTag(code) {
            const list = this.getKeepLiveTags(code);
            const curItem = this.getCurrentTag(code);
            this.setTagList(list);

            if (list.length) {
                this.handleClickTag(list[list.length - 1]);
                this.$eventBus.$emit('closeTag', curItem);
            }
            this.handleMoveArror();
        },

        handleMoveArror() {
            window.setTimeout(() => {
                const width = this.$refs.tags.offsetWidth;
                const wrapWidth = this.$refs.tagsWrap.offsetWidth;

                if (width > wrapWidth) {
                    this.isShowMoveArror = true;
                } else {
                    this.isShowMoveArror = false;
                }
            }, 500);
        },

        getKeepLiveTags(code) {
            return this.tagList.filter(item => item.code !== code);
        },

        getCurrentTag(code) {
            return this.tagList.filter(item => item.code === code);
        },

        ...mapMutations('router', ['setTagList', 'addTag'])
    },
    watch: {
        $route(to) {
            this.getTagByRoute(to);
        },
        isShowContextMenu(val) {
            if (val) {
                document.body.addEventListener('click', this.hideContextMenu);
            } else {
                document.body.removeEventListener('click', this.hideContextMenu);
            }
        }
    },
    mounted: function () {
        this.getTagByRoute(this.$route);
    }
};
</script>

<style lang="less" scoped>
@import '~@/less/sdk/index';

.nav-tags {
    width: 100%;
    height: 32px;
    position: relative;
    background: #fff;
    border-top: 1px solid #DBE2E7;
    box-shadow: 0 4px 2px -2px #ccc;

    .btn-operate {
        position: absolute;
        background: #fff;
        top: 0;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        z-index: 50;
    }

    .btn-operate-close {
        width: 32px;
        right: 0;

        .operate-close-inner {
            width: 32px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .btn-operate-left {
        width: 28px;
        left: 0;
    }

    .btn-operate-right {
        width: 28px;
        right: 33px;
    }

    .tags-wrap {
        height: 100%;
        position: absolute;
        left: 0px;
        right: 33px;
        overflow: hidden;
        z-index: 10;
    }

    .tags {
        display: flex;
        height: 100%;
        white-space: nowrap;
        position: absolute;
        left: 0px;
        top: -2px;
        transition: left 0.3s ease;

        .ivu-tag {
            min-width: 80px;
            height: 33px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #fff;
            cursor: pointer;
            font-size: 14px;
            margin-bottom: 1px;
            border-left: 0px;
            border-bottom: 2px solid #fff;
            margin: 0px;
        }

        /deep/.ivu-icon-ios-close {
            width: 18px;
            height: 22px;
            line-height: 22px;
            font-size: 22px;
            font-weight: 600;
            transform: none;
            top: 0px;
            text-align: center;
        }

        .ivu-tag-primary {
            background: #fff;
            border-bottom: 1px solid #fff;
            margin-bottom: -1px;
            z-index: 9;
            border-bottom: 2px solid @primary-color;

            /deep/.ivu-tag-color-white {
                color: @primary-color !important;
            }

            &/deep/.ivu-icon-ios-close {
                color: @primary-color !important;
                font-weight: 700;
            }
        }
    }

    .context-menu {
        position: absolute;
        margin: 0;
        padding: 5px 0;
        background: #fff;
        z-index: 1000;
        list-style-type: none;
        border-radius: @border-radius-size;
        box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.1);

        li {
            margin: 0;
            padding: 5px 15px;
            cursor: pointer;

            &:hover {
                background: #eee;
            }
        }
    }
}
</style>

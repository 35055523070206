<template>
    <Dropdown :class="{ 'menu-group': data.level === 1 }" placement="right-start">
        <a v-if="data.level === 1" class="drop-menu-a" @click="handlerMenuItemClick(data)" type="text">
            <span :class="['iconfont', data.iconCls]"></span>
        </a>
        <DropdownItem class="menu-item" v-else>
            {{ data.text }}
            <Icon type="ios-arrow-forward"></Icon>
        </DropdownItem>
        <DropdownMenu slot="list" class="menu-subgroup">
            <div v-for="item in data.children" :key="item.id">
                <DropdownItem
                    @click.native="handlerMenuItemClick(item)"
                    :class="['menu-item', activeId == item.to ? 'active-item' : '']"
                    v-if="item.children.length == 0">{{ item.text }}</DropdownItem>
                <menu-item v-if="item.children.length > 0" :data="item"></menu-item>
            </div>
        </DropdownMenu>
    </Dropdown>
</template>
<script>
export default {
    name: 'MenuItem',
    props: {
        data: {
            type: Object
        }
    },
    data() {
        return {
            activeId: ''
        };
    },
    methods: {
        handlerMenuItemClick(item) {
            this.$router.replace({ path: item.to });
            this.activeId = item.to;
        }
    }
};
</script>

<style lang="less">
.menu-group {
    width: 26px;
    margin-bottom: 15px;

    .menu-subgroup {
        background: #fff;
        border-radius: @border-radius-size;
        box-shadow: 3px 3px 2px #ccc;
    }

    .drop-menu-a {
        color: #ddd;

        &:hover {
            color: #fff;
        }
    }

    .menu-item {
        overflow: hidden;
    }

    .ivu-select-dropdown {
        width: auto !important;
        padding: 0px;
    }

    .ivu-dropdown-item:hover {
        color: @primary-color;
        background-color: #eeefff;
    }

    .ivu-dropdown-item.active-item {
        color: @primary-color;
        background-color: #eeefff;
    }

    .ivu-dropdown-rel {
        text-align: center;

        .iconfont {
            font-size: 14px;
        }
    }
}
</style>

// 模板
<template>
    <modal :title="dialogTitle" v-model="isShow" :width="width" @on-cancel="doClose" :mask-closable="false">
        <Form
            ref="form"
            :model="dataModel"
            :rules="validateModel"
            :label-width="labelWidth"
            :show-message="false"
            @submit.native.prevent
        >
            <Row v-for="(item1, idx) in this.$slots.default" :key="'row' + idx">
                <Col
                    v-for="(item2, idx) in item1.children"
                    :key="'col' + idx"
                    :span="item2.data.attrs.span || 24"
                    :style="item2.data.attrs.colStyle"
                    :class-name="'col-wrap' + ' ' + item2.data.attrs.colCls"
                >
                    <Row>
                        <Col
                            v-for="(item3, idx) in item2.children"
                            :key="'cmp-' + idx"
                            :set="(attrs = (item3.data && item3.data.attrs) || {})"
                            :span="item3.data.attrs.span || 24"
                        >
                            <!-- 表单元素 -->
                            <FormItem
                                v-show="
                                    showModel[item3.data.attrs.name] &&
                                    !getHidden(attrs) &&
                                    !(
                                        mode === 'UPDATE' &&
                                        updateHiddenFields &&
                                        updateHiddenFields.length &&
                                        updateHiddenFields.includes(item3.data.attrs.name)
                                    )
                                "
                                :label="attrs.label"
                                :prop="item3.data.attrs.name"
                                :key="'fi' + idx"
                            >
                                <!-- 纯文本显示 -->
                                <span v-if="attrs.type === 'displayfield' || isEmpty(attrs.type)" class="displayfield">
                                    {{
                                        item3.data.attrs.dateFormat
                                            ? formatDatetime(
                                                  dataModel[item3.data.attrs.name],
                                                  item3.data.attrs.dateFormat
                                              )
                                            : dataModel[item3.data.attrs.name]
                                    }}
                                    <a
                                        v-if="attrs.historyTip"
                                        :title="attrs.historyTip"
                                        @click="handleIconClick(item3.data.attrs.name)"
                                        >查看</a
                                    >
                                </span>

                                <!-- 文本框 -->
                                <Input
                                    v-if="attrs.type === 'textbox'"
                                    v-model="dataModel[item3.data.attrs.name]"
                                    :disabled="getDisable(attrs)"
                                    :maxlength="getMaxLength(attrs)"
                                    @on-blur="handleInputBlurChange($event, 'blur', item3.data.attrs)"
                                    @keyup.native="handleInputChange($event, 'keyup', item3.data.attrs)"
                                >
                                    <span slot="prepend" v-if="item3.data.attrs.prefix">{{ attrs.prefix }}</span>
                                </Input>

                                <!-- 数字框 -->
                                <InputNumber
                                    v-if="attrs.type === 'numberbox'"
                                    v-model="dataModel[item3.data.attrs.name]"
                                    :max="attrs.max || 999999"
                                    :min="attrs.min || 0"
                                    :disabled="getDisable(attrs)"
                                    :active-change="true"
                                    @on-change="handleInputChange($event, 'change', item3.data.attrs)"
                                    @on-blur="handleInputBlurChange($event, 'blur', item3.data.attrs)"
                                    @keyup.native="handleInputChange($event, 'keyup', item3.data.attrs)"
                                    style="width: 100%"
                                />

                                <!-- 多行文本框 -->
                                <Input
                                    v-if="attrs.type === 'textarea'"
                                    show-word-limit
                                    type="textarea"
                                    v-model="dataModel[item3.data.attrs.name]"
                                    :autosize="{ minRows: attrs.rows || 3, maxRows: attrs.rows || 3 }"
                                    :maxlength="getMaxLength(attrs)"
                                    :disabled="getDisable(attrs)"
                                />

                                <!-- 列表选择器 -->
                                <SvSelectorField
                                    v-if="attrs.type === 'selectorfield'"
                                    :extraParams="attrs.extraParams"
                                    :model="dataModel[item3.data.attrs.name]"
                                    :codeName="attrs.submitName"
                                    :submitName="dataModel[item3.data.attrs.submitName]"
                                    :title="attrs.title"
                                    :url="attrs.url"
                                    :searchFields="attrs.searchFields"
                                    :gridColumns="attrs.gridColumns"
                                    :disabled="getDisable(attrs)"
                                    :labelWidth="attrs.labelWidth"
                                    :valueField="attrs.valueField"
                                    :nameField="attrs.nameField"
                                    :keyName="attrs.keyName"
                                    :multiSelect="attrs.multiSelect"
                                    :mappingFields="attrs.mappingFields"
                                    width="100%"
                                    @on-change="value => handleSelectorfieldChange(value, item3.data.attrs.submitName)"
                                />

                                <!-- 下拉框 -->
                                <SvCombobox
                                    v-if="attrs.type === 'combobox'"
                                    :disabled="getDisable(attrs)"
                                    v-model="dataModel[item3.data.attrs.name]"
                                    :url="attrs.url"
                                    :displayFormat="attrs.displayFormat"
                                    :autoLoad="attrs.autoLoad"
                                    :expandLoad="attrs.expandLoad"
                                    :dependParamsKey="attrs.dependParamsKey"
                                    :dependParams="attrs.dependParams"
                                    :clearTarget="attrs.clearTarget"
                                    :multiSelect="attrs.multiSelect"
                                    :mappingFields="attrs.mappingFields"
                                    :group="attrs.group"
                                    @onchange="
                                        (value, records) => handleComboboxChange(value, records, item3.data.attrs)
                                    "
                                />

                                <!-- 日历控件 -->
                                <DatePicker
                                    v-if="attrs.type === 'datebox'"
                                    v-model="dataModel[item3.data.attrs.name]"
                                    placement="bottom-end"
                                    dataType="date"
                                    style="width: 100%"
                                    :disabled="getDisable(attrs)"
                                />

                                <!-- 日历时间控件 -->
                                <DatePicker
                                    v-if="attrs.type === 'datetime'"
                                    v-model="dataModel[item3.data.attrs.name]"
                                    placement="bottom-end"
                                    type="datetime"
                                    format="yyyy-MM-dd HH:mm"
                                    style="width: 100%"
                                    :disabled="getDisable(attrs)"
                                />

                                <!-- 区域日历控件 -->
                                <DatePicker
                                    v-if="attrs.type === 'datetimerange'"
                                    v-model="dataModel[item3.data.attrs.name]"
                                    placement="bottom-end"
                                    type="datetimerange"
                                    style="width: 100%"
                                />

                                <!-- 复合文本框 -->
                                <SvEditor
                                    v-if="attrs.type === 'editor'"
                                    :model="dataModel[item3.data.attrs.name]"
                                    ref="editor"
                                />

                                <!-- 上传文本框 -->
                                <SvUploadField
                                    v-if="attrs.type === 'uploadfield'"
                                    :model="dataModel[item3.data.attrs.name]"
                                    :maxSize="attrs.maxSize"
                                    :format="attrs.format"
                                    :multiple="attrs.multiple"
                                    :formatText="attrs.formatText"
                                    :disabled="getDisable(attrs)"
                                    :url="attrs.url"
                                    :isTempExist="attrs.isTempExist"
                                    :TempFileUrl="attrs.TempFileUrl"
                                    @setUploadResult="
                                        model =>
                                            setUploadResult(
                                                model,
                                                item3.data.attrs.name,
                                                item3.data.attrs.submitName,
                                                item3.data.attrs.multiple
                                            )
                                    "
                                    @onClear="
                                        index =>
                                            handleClear(
                                                item3.data.attrs.name,
                                                item3.data.attrs.submitName,
                                                item3.data.attrs.multiple,
                                                index
                                            )
                                    "
                                />

                                <!-- 下拉框树控件  -->
                                <SvTreeNew
                                    v-if="attrs.type === 'newTree'"
                                    :modelCode="dataModel[item3.data.attrs.name]"
                                    :modelName="dataModel[item3.data.attrs.codeName]"
                                    :url="attrs.url"
                                    :disabled="getDisable(attrs)"
                                    @onChange="
                                        data => handleTreeChange(data, item3.data.attrs.name, item3.data.attrs.codeName)
                                    "
                                />
                            </FormItem>

                            <!-- 图片预览与上传 -->
                            <SvImageField
                                v-if="attrs.type === 'imagefield'"
                                :model="dataModel[item3.data.attrs.name]"
                                :width="attrs.width"
                                :height="attrs.height"
                                :src="attrs.src"
                                :prefixSrc="attrs.prefixSrc"
                                :uploadUrl="attrs.uploadUrl"
                            />

                            <Tree
                                ref="tree"
                                v-if="attrs.type === 'tree'"
                                :data="attrs.data || treeData"
                                :url="attrs.url"
                                :render="renderNodes"
                                :name="attrs.name"
                                :required="attrs.required"
                                :class="{ 'tree-wrap': true, required: !isTreeValid }"
                                show-checkbox
                                multiple
                                @on-check-change="handlerTreeCheckChagne"
                            ></Tree>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Form>

        <div slot="footer" v-if="isShowFooter">
            <Button @click="doClose()">关闭</Button>
            <Button type="primary" @click="doSubmit" :loading="loading" v-show="isEdit">保存</Button>
        </div>
        <div v-else slot="footer">
            <slot name="footer-btn"></slot>
        </div>
    </modal>
</template>

// 脚本
<script>
import { request } from '@/network/request';

const COMPS_TYPES = [
    'textbox',
    'numberbox',
    'textarea',
    'selectorfield',
    'combobox',
    'datebox',
    'datetime',
    'datetimerange',
    'editor',
    'imagefield',
    'tree',
    'uploadfield',
    'displayfield',
    'newTree'
];

export default {
    name: 'SvEdit',

    data: function () {
        return {
            dialogTitle: '新增',
            mode: '',
            loading: false,
            isShow: false,
            isTreeValid: true,
            record: {},
            nodeList: [],
            treeData: [],
            showModel: this.getShowModel(),
            dataModel: this.getDataModel(),
            validateModel: this.getValidateModel()
        };
    },

    props: {
        // 弹出框表头
        title: {
            type: String
        },
        // 弹出框宽度
        width: {
            type: Number,
            default: () => 520
        },
        // 字段标签宽度
        labelWidth: {
            type: Number,
            default: () => 100
        },
        isEdit: {
            type: Boolean,
            default: true
        },
        isShowFooter: {
            type: Boolean,
            default: true
        },
        updateHiddenFields: {
            type: Array
        }
    },

    mounted() {
        this.loadTreeData();
    },

    methods: {
        /**
         * 打开编辑框
         *  @param {String} mode
         *  @param {Object} record
         */
        show(mode, record) {
            this.getParams();
            this.mode = mode;
            this.isShow = true;
            this.record = record || {};
            this.setTitle();
            this.setRecord(record);
        },

        /**
         *  设置弹出框抬头
         */
        setTitle() {
            if (this.mode === 'CREATE') {
                this.dialogTitle = '创建-' + (this.title || '');
            } else if (this.mode === 'UPDATE') {
                this.dialogTitle = '更新-' + (this.title || '');
            }
        },

        /**
         *  设置表单数据
         *  @param {Object} record
         */
        setRecord(record) {
            if (record) {
                for (let key in this.dataModel) {
                    const item = this.findFormItems(key);
                    const { type, dataType, format } = item;
                    const val = record[key];

                    if (dataType === 'date' || type === 'datebox') {
                        this.dataModel[key] = this.formatDatetime(val, format);
                    } else if (dataType === 'number') {
                        if (this.isEmpty(val)) {
                            this.dataModel[key] = 0;
                        } else {
                            this.dataModel[key] = Number(val);
                        }
                    } else if (dataType === 'tree') {
                        this.setTreeChecked(record[item.name]);
                    } else {
                        this.$set(this.dataModel, key, val);
                    }
                }
            }
        },

        // 获取上传结果保存到model内
        setUploadResult(model, field, submitField, multiple) {
            if (multiple) {
                this.dataModel[field].push(model);
                this.dataModel[submitField].push(model.id);
            } else {
                this.dataModel[field] = model;
                this.dataModel[submitField] = model.id;
            }
        },
        // 清除上传文件
        handleClear(field, submitField, multiple, index) {
            if (multiple) {
                this.dataModel[field].splice(index, 1);
                this.dataModel[submitField].push(index, 1);
            } else {
                this.dataModel[field] = {};
                this.dataModel[submitField] = '';
            }
        },
        /**
         *  设置被选中的节点
         *  @param {Array} checkedKeys
         */
        setTreeChecked(checkedKeys) {
            for (const id of checkedKeys) {
                this.nodeList.map(item => {
                    if (item.node.id === id) {
                        this.$set(item.node, 'checked', true);
                    }
                });
            }
        },

        /**
         *  渲染树节点
         *  @param {Function} h
         *  @param {Object} root
         *  @param {Object} data
         */
        renderNodes(h, { root, data }) {
            this.nodeList = root;

            return h('span', data.text);
        },

        /**
         *  设置表单值
         *  @param {Object} values
         */
        setValues(values) {
            for (let key in values) {
                this.dataModel[key] = values[key];
            }
        },

        /**
         *  设置field值
         *  @param {string} value
         *  @param {string} name
         */
        setFieldValue(name, value) {
            this.dataModel[name] = value;
        },

        /**
         *  表单提交
         */
        async doSubmit(status) {
            // this.getParams();
            const isValid = await this.$refs.form.validate();
            this.isTreeValid = this.validateTree();

            if (isValid && this.isTreeValid) {
                const params = this.getParams();
                this.loading = true;
                if (status) {
                    this.$emit('onSubmit', params, this.mode, status, success => {
                        if (success) {
                            this.doClose();
                        }
                        this.loading = false;
                    });
                    return;
                } else {
                    this.$emit('onSubmit', params, this.mode, '', success => {
                        if (success) {
                            this.doClose();
                        }
                        this.loading = false;
                    });
                }
            } else {
                this.$Message.error('表单必填项不能为空!');
            }
        },

        /**
         *  关闭表单
         */
        doClose() {
            this.isShow = false;
            this.isTreeValid = true;
            this.doReset();
        },

        /**
         *  重置表单
         */
        doReset() {
            // 清除表单值
            this.dataModel = this.getDataModel();
            for (let key in this.dataModel) {
                const item = this.findFormItems(key);
                if (item.type === 'uploadfield') {
                    this.dataModel[key] = null;
                } else if (item.dataType == 'number') {
                    this.dataModel[key] = 0;
                } else {
                    this.dataModel[key] = '';
                }
            }

            // 重置树节点
            for (let item of this.nodeList) {
                item.node.checked = false;
                item.node.indeterminate = false;
            }

            // 重置验证信息
            this.$refs.form.resetFields();

            this.loading = false;
        },

        /**
         *  处理树节点是否被选中
         */
        handlerTreeCheckChagne() {
            let treeList = this.getTreeSelectionValues();

            if (treeList && treeList.length) {
                this.isTreeValid = true;
            } else {
                this.isTreeValid = false;
            }
        },

        /**
         *  处理输入改变
         * @param {String} name
         * @param {Object} e
         * @param {String} eventName
         */
        handleInputChange(e, eventName, attrs) {
            const { name } = attrs;
            const value = eventName === 'keyup' ? e.target.value : this.dataModel[name];

            if (typeof attrs.onchange === 'function') {
                attrs.onchange(value);
            }

            this.$emit('onInputChange', name, value);
        },

        handleInputBlurChange(e, eventName, attrs) {
            const { name } = attrs;
            const value = eventName === 'blur' ? e.target.value : this.dataModel[name];

            if (typeof attrs.onblur === 'function') {
                attrs.onblur(value);
            }

            this.$emit('onInputBlurChange', name, value);
        },

        /**
         *  下拉值改变
         * @param {String} value
         * @param {Object} records
         * @param {Object} attrs
         */
        handleComboboxChange(value, records, attrs) {
            if (typeof attrs.onchange === 'function') {
                attrs.onchange(value, records);
            }

            this.$emit('onComboboxChange', value, records, attrs.name);
        },

        handleSelectorfieldChange(value, field) {
            this.dataModel[field] = value.code;
        },

        /**
         * 字段内图标点击事件
         * @param {String} name
         */
        handleIconClick(name) {
            this.$emit('onIconClick', name);
        },

        /**
         * 验证树控件是否有节点被选中
         */
        validateTree() {
            let tree = this.$refs.tree;

            if (tree) {
                if (tree[0].$attrs.required === 'true') {
                    let treeList = this.getTreeSelectionValues();
                    if (this.isEmpty(treeList)) {
                        return false;
                    } else {
                        return true;
                    }
                }
            }

            return true;
        },

        /**
         * 加载树控件数据
         */
        loadTreeData() {
            const tree = this.$refs.tree;

            if (tree && tree.length) {
                const url = tree[0].$attrs.url;
                this.showLoading = true;

                request
                    .get(url)
                    .then(data => {
                        this.showLoading = false;
                        this.treeData = data.list;
                        this.$nextTick(() => {
                            this.expandAll();
                        });
                    })
                    .catch(() => {
                        this.showLoading = false;
                    });
            }
        },

        /**
         * 获取显示隐藏模型
         */
        getShowModel() {
            const showModel = {};
            const items = this.getFormItems(this.$slots.default);

            items.forEach(item => {
                const name = item.data.attrs.name;

                showModel[name] = true;
            });

            return showModel;
        },

        /**
         * 设置字段显示或隐藏
         * @param {Array, string} fields
         * @param {Boolean} isHidden
         */
        setFieldsHide(fields, isHidden = true) {
            if (Array.isArray(fields)) {
                fields.forEach(field => {
                    this.showModel[field] = !isHidden;
                    if (this.validateModel[field]) {
                        this.validateModel[field][0].required = !isHidden;
                    }
                });
            } else {
                this.showModel[fields] = !isHidden;
                if (this.validateModel[fields]) {
                    this.validateModel[fields][0].required = !isHidden;
                }
            }
        },

        /**
         * 获取表单字段模型
         */
        getDataModel() {
            let dataModel = {};
            const items = this.getFormItems(this.$slots.default);
            items.forEach(item => {
                const { name, value, dataType, type, submitName, multiple } = item.data.attrs;
                if (type === 'uploadfield') {
                    if (!value) {
                        if (multiple) {
                            dataModel[name] = [];
                            dataModel[submitName] = [];
                        } else {
                            dataModel[name] = null;
                            dataModel[submitName] = '';
                        }
                    } else {
                        dataModel[name] = value;
                    }
                } else if (type === 'selectorfield') {
                    if (this.isEmpty(value)) {
                        dataModel[name] = '';
                        dataModel[submitName] = '';
                    } else {
                        dataModel[name] = value;
                        dataModel[submitName] = value;
                    }
                } else {
                    if (dataType === 'number') {
                        if (this.isEmpty(value)) {
                            dataModel[name] = 0;
                        } else {
                            dataModel[name] = value;
                        }
                    } else if (dataType === 'array') {
                        if (this.isEmpty(value)) {
                            dataModel[name] = [];
                        } else {
                            dataModel[name] = value;
                        }
                    } else {
                        if (this.isEmpty(value)) {
                            dataModel[name] = '';
                        } else {
                            dataModel[name] = value;
                        }
                    }
                }
            });
            return dataModel;
        },

        /***
         * 获取表单验证规则模型
         */
        getValidateModel() {
            let validateModel = {};
            const items = this.getFormItems(this.$slots.default);

            items.forEach(item => {
                const attrs = item.data.attrs;
                const { name, required } = attrs;

                if (required === 'true') {
                    validateModel[name] = [
                        {
                            required: true,
                            message: ' ',
                            trigger: 'blur',
                            type: this.getDataType(attrs)
                        }
                    ];
                }
            });

            return validateModel;
        },

        /***
         * 获取表单全部字段(递归)
         * @param {Array} data
         */
        getFormItems(data) {
            let nodes = [];

            data.forEach(item => {
                let subNodes = [];
                let type = item.data.attrs.type;

                if (item.children && item.children.length) {
                    subNodes = this.getFormItems(item.children);
                }
                if (subNodes.length > 0) {
                    nodes = nodes.concat(subNodes);
                } else if (COMPS_TYPES.indexOf(type) > -1) {
                    nodes.push(item);
                }
            });

            return nodes;
        },

        /***
         * 查找表单字段
         * @param {String} name
         */
        findFormItems(name) {
            let attrs = {};
            let items = this.getFormItems(this.$slots.default);

            items.forEach(item => {
                if (item.data.attrs.name === name) {
                    attrs = item.data.attrs;
                }
            });

            return attrs;
        },

        /***
         * 获取表单信息
         * @param {String} name
         */
        getParams() {
            const params = {};
            const fields = this.getFormItems(this.$slots.default);
            fields.forEach(item => {
                const attrs = item.data.attrs;
                const { name, noSubmit, type, format, submitName, codeName } = attrs;
                const propsData = this.getPropsData(name, submitName);
                const value = (propsData && propsData.value) || null;

                if (noSubmit !== 'true') {
                    if (type === 'datebox' || type === 'datetime') {
                        params[name] = this.formatDatetime(value, format || 'YYYY-MM-DD');
                    } else if (type == 'editor') {
                        params[item.prop] = item.$children[0].editorData;
                    } else if (type === 'tree') {
                        params[name] = this.getTreeSelectionValues();
                    } else if (type === 'selectorfield') {
                        if (propsData.multiple) {
                            params[name] = propsData.model.length == 0 ? [] : (propsData.model || '').split(',');
                        } else {
                            params[name] = propsData.model;
                            params[submitName] = propsData.submitName;
                        }
                    } else if (type === 'uploadfield') {
                        if (Array.isArray(propsData.model)) {
                            params[submitName] = propsData.model.map(item => item.id);
                        } else {
                            params[submitName] = propsData.model ? propsData.model.id : '';
                        }
                    } else if (type === 'combobox') {
                        params[name] = propsData && propsData.value;
                    } else if (type === 'newTree') {
                        params[name] = propsData && propsData.modelCode;
                        params[codeName] = propsData && propsData.modelName;
                    } else {
                        params[name] = (propsData && propsData.value) || null;
                    }
                }
            });
            return params;
        },

        /***
         * 获取props数据信息
         * @param {String} name
         */
        getPropsData(name, submitName) {
            const fields = this.$refs.form.fields;

            for (let item of fields) {
                const opts = item.$children;
                if (opts.length) {
                    if (item.prop === name || item.prop === submitName) {
                        return opts[0].$options.propsData;
                    }
                }
            }

            return null;
        },

        /***
         * 获取选择树节点
         * @param {Object} attrs
         */
        getTreeSelectionValues() {
            let values = [];

            this.nodeList.map(item => {
                if (item.node.checked) {
                    return values.push(item.node.id);
                }
            });

            return values;
        },

        /***
         * 获取字段禁用属性
         * @param {Object} attrs
         */
        getDisable(attrs) {
            const disabled = attrs.disabled || '';
            const updateDisabled = attrs.updateDisabled || '';
            const isUpdate = this.mode === 'UPDATE';

            return disabled === 'true' || (isUpdate && updateDisabled === 'true');
        },

        /***
         * 将hidden配置属性转换成布尔值
         * @param {Object} attrs
         */
        getHidden(attrs) {
            const hidden = attrs.hidden || '';
            const updateDiplayed = attrs.updateDiplayed || '';
            const isUpdate = this.mode === 'CREATE';

            return hidden === 'true' || (isUpdate && updateDiplayed === 'true');
        },

        /***
         * 获取item配置attrs
         * @param {Object} item
         * @param {String} key
         */
        getAttrs(item, key) {
            return item.data.attrs[key];
        },

        /***
         * 获取字段输入最大长度
         * @param {Object} attrs
         */
        getMaxLength(attrs) {
            return this.isEmpty(attrs.maxlength) ? 200 : attrs.maxlength;
        },

        /***
         * 获取字段数据类型
         * @param {Object} attrs
         */
        getDataType(attrs) {
            const { type, dataType } = attrs;

            if (type === 'datebox' || dataType === 'date') {
                return 'date';
            } else if (type === 'numberbox' || dataType === 'num') {
                return 'number';
            } else if (dataType === 'object') {
                return 'object';
            } else if (dataType === 'array') {
                return 'array';
            } else {
                return 'string';
            }
        },

        /***
         * 展开树全部节点
         */
        expandAll() {
            this.nodeList.map(item => this.$set(item.node, 'expand', true));
        },

        handleTreeChange(data, name, codeName) {
            this.dataModel[name] = data.map(item => item.code).join(',');
            this.dataModel[codeName] = data.map(item => item.title).join(',');
        }
    }
};
</script>

// 样式
<style lang="less" scoped>
.col-wrap {
    padding: 1px;
}

.image-wrap {
    display: flex;
    justify-content: center;
    border-left: 1px solid #ccc;
}

.tree-wrap {
    overflow: auto;
    height: 450px;
    border: 1px solid #ccc;
    border-radius: 3px;
    padding: 5px;
}

.required {
    border: 1px solid red;
}

.ivu-modal-body {
    .ivu-form-item {
        margin-right: 10px;
    }
}

/deep/.ivu-form-item-label {
    margin-bottom: 10px;
    padding: 6px 5px !important;
}
</style>

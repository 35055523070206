<template>
  <div class="viewport">
    <div class="search" slot="search" v-if="!noSearch">
      <slot name="search"></slot>
    </div>
    <div class="grid" slot="grid">
      <slot name="grid"></slot>
    </div>
  </div>
</template>
<script>
export default {
    name: 'Viewport',
    props: {
        noSearch: {
            type: Boolean,
            default: false
        }
    }
};
</script>
<style lang="less" scoped>
.viewport {
    height: 100%;
}
.search {
    position: relative;
    border-radius: @border-radius-size;
    background: #fff;
    padding: 0px;
    margin-bottom: 8px;
}
.grid {
    position: relative;
    border-radius: @border-radius-size;
    background: #fff;
    padding: 18px 10px;
}
</style>

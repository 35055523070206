<template>
    <div class="sv-upload-field">
        <div class="sv-upload">
            <Input
                ref="filebox"
                v-if="!multiple"
                :readonly="true"
                :value="model ? fileName : ''"
                clearable
                @on-clear="handleClear"
            />
            <div v-else class="file-list-wrap">
                <div v-for="(file, index) in fileList" :key="index" class="file-item">
                    <div class="file-name" :title="file.name">{{ file.name }}</div>
                    <Icon type="md-close" @click="handleClear(index)" />
                </div>
            </div>
            <a v-if="false" href="javascript:;" class="preview" @click="previewFile">预览</a>
            <sv-upload
                :url="url"
                @onSuccess="handlerUploadSuccess"
                :format="format"
                :multiple="multiple"
                :onFormatError="handlerFormatError"
                :maxSize="maxSize"
            >
                <Button icon="ios-cloud-upload-outline" :loading="loading">浏览</Button>
            </sv-upload>

            <slot name="extra"></slot>
        </div>
        <div class="format" v-if="format">
            <span v-if="formatText">
                {{ formatText }}
            </span>
            <span v-else> 请上传 {{ format.toString() }} 类型文件 </span>
        </div>

        <a v-if="isTempExist" class="preview" @click="previewFile">下载模板</a>
        <div></div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            fileName: '',
            formatError: false,
            fileUrl: '',
            fileList: []
        };
    },
    props: {
        name: {
            type: String,
            default: 'field'
        },
        loading: {
            type: Boolean,
            default: false
        },
        model: {
            type: [Object, Array]
        },
        format: {
            type: Array,
            default: () => []
        },
        formatText: {
            type: String
        },
        maxSize: {
            type: Number
        },
        url: {
            type: String,
            default: ''
        },
        isTempExist: {
            type: Boolean,
            default: false
        },
        TempFileUrl: {
            type: String,
            default: ''
        },
        multiple: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        model: {
            handler(data) {
                if (data) {
                    if (Array.isArray(data)) {
                        // 目前还没用到多文件逻辑，后续需要再根据需求设计
                        this.fileList = data;
                    } else {
                        this.fileName = data.name || '';
                        this.fileUrl = data.uri || '';
                    }
                }
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        handlerUploadSuccess(data) {
            this.loading = false;
            if (data.success) {
                if (data.result.length) {
                    this.fileName = data.result[0].name;
                    this.fileUrl = data.result[0].uri;
                    this.$emit('setUploadResult', data.result[0]);
                }
            } else {
                this.$Message.error(data.message);
            }
        },
        handleClear(index) {
            this.$emit('onClear', index);
        },
        handlerFormatError() {
            this.formatError = true;
        },
        previewFile() {
            const url = this.TempFileUrl;
            window.open(url, '_blank');
        }
    }
};
</script>

<style lang="less">
@import '~@/less/sdk/index';

.sv-upload-field {
    flex-direction: row;
    width: 100%;
    align-items: right;

    .preview {
        cursor: pointer;
        display: inline-block;
        width: 60px;
        text-align: center;
        text-decoration: underline;
    }

    .sv-upload {
        width: 100%;
        display: flex;
        flex-direction: row;
        width: 100%;
    }
    .file-list-wrap {
        flex: 1;
        min-width: 0;
        .file-item {
            width: 100%;
            display: flex;
            align-items: center;
            .file-name {
                flex: 1;
                color: #0060bf;
                margin-right: 5px;
                min-width: 0;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            i{
                cursor: pointer;
                &:hover{
                    color: #0060bf;
                }
            }
        }
    }

    .format {
        max-width: 200px;
    }

    .ivu-upload {
        margin-left: 5px;

        .ivu-btn {
            &.ivu-btn-small {
                height: 24px;
            }
        }
    }

    .error {
        color: #ed4014;
    }
}
</style>

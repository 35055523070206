import detailPageRouter from './routers/detailPageRouter';

export default [
    {
        path: '/',
        redirect: '/engineerin-consult/response-statistics'
    },
    {
        path: '/no-access',
        meta: {
            title: '无权限',
            notAuth: true
        },
        component: () => import('@/pages/errorPages/401')
    },
    {
        name: 'M100-1',
        path: '/consult-management/engineering-consulting/detail',
        meta: {
            title: 'TISM工程咨询详情',
            notAuth: true
        },
        component: () => import('@/pages/consultManagement/engineeringConsulting/detail')
    },
    {
        name: 'M200-1',
        path: '/consult-management/diagnosis-feedback/detail',
        meta: {
            title: '诊断仪反馈详情',
            notAuth: true
        },
        component: () => import('@/pages/consultManagement/diagnosisFeedback/detail')
    },
    {
        name: 'M300-1',
        path: '/diagnosis-management/diagnosis-software/detail',
        meta: {
            title: '诊断仪软件迭代详情',
            notAuth: true
        },
        component: () => import('@/pages/diagnosisManagement/diagnosisSoftware/detail')
    },
    ...detailPageRouter
];

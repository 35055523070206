<template>
    <Button class="button" :custom-icon="'iconfont' + ' ' + icon" v-show="!hidden">{{ text }}</Button>
</template>

<script>
export default {
    props: {
        icon: {
            type: String
        },
        text: {
            type: String
        },
        hidden: {
            type: Boolean,
            default: () => false
        }
    }
};
</script>

<style lang="less" scoped>
.button {
    margin-left: 10px;
    height: 25px;
    padding: 3px;
}
</style>
<template>
    <BackTop @on-click="backTop(80)">
        <div class="top">
            <i class="iconfont icon-s-up" />
        </div>
    </BackTop>
</template>

<script>
export default {
    props: {
        scrollTarget: {
            type: HTMLDivElement
        }
    },

    watch: {
        scrollTarget() {
            this.initEvents();
        }
    },

    methods: {
        initEvents() {
            this.scrollTarget.addEventListener('scroll', this.handleScroll);
        },

        handleScroll() {
            let backTop = document.querySelector('.ivu-back-top');

            if (this.scrollTarget.scrollTop > 100) {
                backTop.style.display = 'block';
            } else {
                backTop.style.display = 'none';
            }
        },

        backTop(step) {
            let time;
            this.scrollTarget.scrollTop -= step;

            if (this.scrollTarget.scrollTop > 0) {
                time = setTimeout(() => this.backTop(step), 15);
            } else {
                clearTimeout(time);
            }
        }
    }
};
</script>

<style lang="less" scoped>
.top {
    padding: 5px 2px;
    background: rgba(0, 153, 229, 0.7);
    color: #fff;
    text-align: center;
    border-radius: 5px;
}
</style>
import { request } from '@/network/request';
import { storage } from '@/common/storage';
import { isEmpty, getCookie } from '@/common/utils';
import vue from 'vue';

// 加载用户信息
function loadUserInfo() {
    const url = '/consult/sys/user/user-info';

    request
        .get(url)
        .then(data => {
            if (data.success) {
                setUserInfo(data.result || {});
                redirect();
            } else {
                vue.prototype.$Message.error(data.message);
            }
        })
        .catch(error => {
            vue.prototype.$Message.error(error);
        });
}

// 设置用户信息
function setUserInfo(data) {
    const { id, username, authCodes } = data;

    storage.setUserInfo({ id: id, username: username });
    storage.setPermission({ authCodes: authCodes });
}

// 重定向
function redirect() {
    // const lastPage = storage.getLastRoute();

    window.location.href = '/engineerin-consult/response-statistics';
}

export default function () {
    const urlParams = new URLSearchParams(window.location.search);
    const cookieToken = getCookie('token') || urlParams.get('token');
    const storageToken = storage.getToken();

    if (!isEmpty(cookieToken) && isEmpty(storageToken)) {
        storage.setToken(cookieToken);
    } else if (!isEmpty(cookieToken) && !isEmpty(storageToken) && cookieToken !== storageToken) {
        storage.setToken(cookieToken);
    }

    loadUserInfo();
}

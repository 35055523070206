import moment from 'moment';
import axios from 'axios';
import config from 'config';
import { storage } from '@/common/storage';
import { Message } from 'view-design';

/**
 * 格式化日期与时间
 * @param {Number} timestamp
 * @param {String} datetime
 */
export function formatDatetime(timestamp, datetime) {
    if (!timestamp) {
        return '';
    }
    if (datetime === 'UTC') {
        return moment(timestamp).format();
    }

    return moment(timestamp).format(datetime || 'YYYY-MM-DD HH:mm:ss');
}

/**
 * 是否存在列表范围
 * @param {String} code
 * @param {Array} list
 * @param {String} key
 */
export function isIncluded(code, list, key) {
    key = key || 'code';
    for (let i = 0; i < list.length; i++) {
        if (list[i][key] === code) {
            return true;
        }
    }
    return false;
}

/**
 * 获取对象
 * @param {Object} obj
 * @param {String} key
 */
export function getObj(obj, key) {
    if (obj[key]) {
        return obj[key];
    } else {
        return {};
    }
}

/**
 * 是否为空
 * @param {any} value
 */
export function isEmpty(value) {
    return value == null || value === '' || (Array.isArray(value) && value.length === 0);
}

/**
 * 格式化查询参数
 * @param {Object} filter
 * @param {number} curPageIdx
 * @param {number} pageSize
 */
export function formatQueryParams(filter, curPageIdx, pageSize) {
    return {
        filter: filter || {},
        sorts: [],
        paging: {
            page: curPageIdx || 1,
            size: pageSize || 20
        }
    };
}

/**
 * 格式化查询参数
 * @param {Object} filter
 * @param {number} curPageIdx
 */
export function getPageQueryParams(filter, curPageIdx, pageSize) {
    const queryParams = formatQueryParams(filter, curPageIdx, pageSize);

    return 'args=' + encodeURIComponent(JSON.stringify(queryParams));
}

/**
 * 防抖动方法
 * @param {Function} func
 * @param {number} wait
 */
export function getDebounce(func, wait = 1000) {
    let timer;

    return params => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            func(params);
        }, wait);
    };
}

/**
 * @param {String} url
 * @description 从URL中解析参数
 */
export const getParams = url => {
    if (url.split('?').length > 1) {
        const keyValueArr = url.split('?')[1].split('&');
        let paramObj = {};
        keyValueArr.forEach(item => {
            const keyValue = item.split('=');
            paramObj[keyValue[0]] = keyValue[1];
        });
        return paramObj;
    } else {
        return { unionid: null };
    }
};

/**
 * @param {Object} params
 * @param {String} url
 * @param {String} filename
 * @param {Function} callback
 * @param {String} method
 * @description 导出二进制文件流
 */
export const blobExport = (method = 'post', url, filename, params, callback) => {
    if (method === 'post') {
        axios
            .post(config.baseURL + url, params, {
                headers: {
                    Authorization: `Bearer ${storage.getToken()}`,
                    unionId: storage.getAuthorization().unionId
                },
                responseType: 'blob'
            })
            .then(res => {
                const link = document.createElement('a');
                const blob = new Blob([res.data], { type: 'application/octet-stream' });
                link.style.display = 'none';
                link.href = URL.createObjectURL(blob);
                link.setAttribute('download', `${filename || '导出文件'}.xlsx`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                callback && callback({ success: true });
            })
            .catch(err => {
                if (err.response.data.type === 'application/json') {
                    const reader = new FileReader();
                    reader.onload = () => {
                        Message.error(JSON.parse(reader.result).message || '');
                    };
                    reader.readAsText(err.response.data);
                    callback && callback({ success: false });
                    return;
                }
            });
    } else if (method === 'get') {
        axios
            .get(config.baseURL + url, {
                headers: {
                    Authorization: `Bearer ${storage.getToken()}`,
                    unionId: storage.getAuthorization().unionId
                },
                responseType: 'blob'
            })
            .then(res => {
                const link = document.createElement('a');
                const blob = new Blob([res.data], { type: 'application/octet-stream' });
                link.style.display = 'none';
                link.href = URL.createObjectURL(blob);
                link.setAttribute('download', `${filename || '导出文件'}.xlsx`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                callback && callback({ success: true });
            })
            .catch(err => {
                if (err.response.data.type === 'application/json') {
                    const reader = new FileReader();
                    reader.onload = () => {
                        Message.error(JSON.parse(reader.result).message || '');
                    };
                    reader.readAsText(err.response.data);
                    callback && callback({ success: false });
                    return;
                }
            });
    }
};

/**
 * @param {Number} amount
 * @param {Number} num
 * @param {Boolean} hasSymbol
 * @description 格式化金额
 */
export const formatAmount = (amount = 0, num = 2, hasSymbol = true) => {
    const reg = /(\d)(?=(\d{3})+\.)/g;
    if (hasSymbol) {
        return (
            '¥' +
            Number(amount)
            .toFixed(num)
            .replace(reg, '$1,')
        );
    } else {
        return Number(amount)
            .toFixed(num)
            .replace(reg, '$1,');
    }
};

/**
 * @param {Number} mileage
 * @description 格式化公里数
 */
export const formatMileage = (mileage = 0) => {
    return mileage + ' KM';
};

/**
 * @param {Number} str
 * @param {Number} data
 * @description 解析字符串模板 例：“{key1}-{key2}...”
 */
export const parseForamt = (str = '', data = {}, defaultValue = '') => {
    const reg = /\{(.+?)\}/g;
    let result = null;
    let newStr = str;

    do {
        result = reg.exec(str);
        if (result) {
            const key = result[1];
            const value = isEmpty(data[key]) ? defaultValue : data[key];

            newStr = newStr.replace('{' + key + '}', value);
        }
    } while (result);

    return newStr;
};

/**
 * @description 返回上个月份
 */
export const getLastMonth = () => {
    return new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1);
};

/**
 * @param {String} path
 * @description 拼接资源路径
 */
export const spliceResPath = (path) => {
    if (isEmpty(path)) {
        return '';
    }

    return config.resURL + path;
};

/**
 * @param {String} name
 * @description 获取cookie
 */
export function getCookie(name) {
    const cookieArr = document.cookie.split(";");

    for (let i = 0; i < cookieArr.length; i++) {
        let cookiePair = cookieArr[i].split("=");

        if (name == cookiePair[0].trim()) {
            return decodeURIComponent(cookiePair[1]);
        }
    }

    return null;
}


/**
 * @param {String} url
 * @description url添加token信息
 */
export function appendTokenToUrl(url) {
    const token = storage.getToken();

    if (url.indexOf('?') === -1) {
        return `${url}?token=${token}`;
    }

    return `${url}&token=${token}`;
}
<template>
    <div class="main-layout">
        <!--- 头部 -->
        <header-bar />

        <!--- 主容器 -->
        <div class="main-container">
            <!-- 左边菜单 -->
            <div :class="{ 'side-menu': true, 'mini-menu': isMiniMenu }">
                <div class="menu-wrap">
                    <!-- 正常菜单 -->
                    <side-menu ref="sideMenu" :data="menuList" v-show="!isMiniMenu" />

                    <!-- 迷你菜单 -->
                    <mini-menu ref="miniMenu" :data="menuList" v-show="isMiniMenu" />
                </div>
            </div>

            <!-- 右边内容 -->
            <div class="body-wrap">

                <!-- 打开页面tab选项卡 -->
                <nav-tags @clickTag="handleNavTagClick" />

                <!-- 内容区域 -->
                <div class="content-wrap">
                    <keep-alive :include="cachedPages">
                        <router-view></router-view>
                    </keep-alive>
                </div>
            </div>
        </div>

        <Message />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SideMenu from './side-menu';
import MiniMenu from './mini-menu';
import Message from './message';
import HeaderBar from '../common/header-bar';
import NavTags from './nav-tags';

export default {
    name: 'MainLayout',
    components: {
        SideMenu,
        MiniMenu,
        HeaderBar,
        NavTags,
        Message
    },
    data() {
        return {
            isMiniMenu: false
        };
    },
    computed: {
        ...mapGetters('router', ['cachedPages', 'menuList'])
    },
    mounted() {
        this.$eventBus.$on('closeTag', () => {
            this.include = [];
        });

        this.$eventBus.$on('onExpandMenu', (isExpand) => {
            this.isMiniMenu = !isExpand;
        });
    },
    methods: {
        handleNavTagClick(item) {
            this.$refs.sideMenu.setOpenedNames(item.code);
        },
        onTriggerMenu() {
            this.isMiniMenu = !this.isMiniMenu;
        }
    }
};
</script>
<style lang="less" scoped>
@import '~@/less/sdk/index';

.main-layout {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;

    .main-container {
        height: calc(100% - 48px);
        flex: 1;
        display: flex;
        flex-direction: row;

        .body-wrap {
            display: flex;
            flex: 1;
            flex-direction: column;
            overflow: hidden;
        }

        .content-wrap {
            display: flex;
            flex: 1;
            flex-direction: column;
            margin: 0px;
            padding: 8px;
            height: calc(100% - 90px);

            div {
                height: 100%;
            }
        }
    }
}

.side-menu {
    display: flex;
    flex-direction: column;
    width: 255px;
    min-width: 208px;
    height: 100%;
    overflow: hidden;

    .menu-wrap {
        flex: 1;
    }

    .menu-trigger {
        height: 40px;
        width: 100%;
        border-top: 1px solid @back-6;
        line-height: 39px;
        padding-left: 18px;

        .iconfont {
            cursor: pointer;
            color: #878787;
        }
    }

    &.mini-menu {
        width: 53px;
        min-width: 53px;
    }
}
</style>

class StorageService {
    _tokenKey = 'token';
    _userInfoKey = 'userInfo';
    _lastRoute = 'lastRoute';
    _routerLoadStatusKey = 'routerLoadStatus';
    _pages = 'pages';
    _permission = 'permission';

    /**
     * 获取Authorization
     */
    getAuthorization() {
        const token = this.getToken();

        return {
            Authorization: `${token}`
        };
    }

    /**
     * 设置打开tab页面
     */
    setPages(params) {
        localStorage.setItem(this._pages, JSON.stringify(params));
    }

    /**
     * 获取打开tab页面
     */
    getPages() {
        let pages = localStorage.getItem(this._pages);

        return pages ? JSON.parse(pages) : [];
    }

    /**
     * 获取打开tab页面
     */
    removePages() {
        localStorage.removeItem(this._pages);
    }

    /**
     * 设置最后一次访问的路由地址
     * @param {String} lastRoute
     */
    setLastRoute(lastRoute) {
        return localStorage.setItem(this._lastRoute, lastRoute);
    }

    /**
     * 获取最后一次访问的路由地址
     */
    getLastRoute() {
        return localStorage.getItem(this._lastRoute);
    }

    /**
     * 获取最后一次访问的路由地址
     */
    removeLastRoute() {
        return localStorage.removeItem(this._lastRoute);
    }

    /**
     * 设置Token
     * @param {String} token
     */
    setToken(token) {
        localStorage.setItem(this._tokenKey, token);
    }

    /**
     * 获取Token
     */
    getToken() {
        return localStorage.getItem(this._tokenKey) || '';
    }

    /**
     * 删除Token
     */
    removeToken() {
        return localStorage.removeItem(this._tokenKey);
    }

    /**
     * 设置用户信息
     * @param {String} userInfo
     */
    setUserInfo(userInfo) {
        localStorage.setItem(this._userInfoKey, JSON.stringify(userInfo));
    }

    /**
     * 获取用户信息
     */
    getUserInfo() {
        const userInfo = localStorage.getItem(this._userInfoKey);

        return JSON.parse(userInfo || '{}');
    }

    /**
     * 获取用户信息
     */
    removeUserInfo() {
        return localStorage.removeItem(this._userInfoKey);
    }

    /**
     * 设置路由加载状态
     * @param {String} status
     */
    setRouterLoadStatus(status) {
        localStorage.setItem(this._routerLoadStatusKey, status);
    }

    /**
     * 获取路由加载状态
     * @param {String} status
     */
    getRouterLoadStatus() {
        return localStorage.getItem(this._routerLoadStatusKey);
    }

    /**
     * 删除路由加载状态
     * @param {String} status
     */
    removeRouterLoadStatus() {
        return localStorage.removeItem(this._routerLoadStatusKey);
    }

    /**
     * 设置权限
     * @param {Array} info
     */
    setPermission(info) {
        localStorage.setItem(this._permission, JSON.stringify(info));
    }

    /**
     * 获取权限配置
     */
    getPermission() {
        const permission = localStorage.getItem(this._permission);

        return permission;
    }

    /**
     * 删除权限信息
     */
    removePermission() {
        return localStorage.removeItem(this._permission);
    }

    /**
     * 判断是否存在权限
     * @param {String} value
     */
    isHasPermission(value) {
        const permission = localStorage.getItem(this._permission);

        return permission.includes(value);
    }

    /**
     * 获取返回地址(供oAuth登录返回使用)
     */
    getReturnUrl() {
        return window.location.origin + '/auth';
    }

    /**
     * 获取home页面
     */
    getHomePage() {
        return 'home';
    }

    /**
     * 移除存储资源
     */
    removeStorage() {
        this.removeToken();
        this.removeRouterLoadStatus();
        this.removeUserInfo();
        this.removePermission();
        this.removePages();
    }
}

export const storage = new StorageService();
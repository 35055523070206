<template>
  <div class="message-wrap" v-if="isShow">
    <span class="message-icon">
      <Icon type="ios-information" />
    </span>
    <span class="content-wrap text-ellipsis">{{ content }}</span>
    <span class="close-icon">
      <Icon type="md-close" @click="handleClose" />
    </span>
  </div>
</template>

<script>


export default {
  data() {
    return {
      isShow: false
    };
  },

  methods: {
    handleClose() {
      this.isShow = false;
    }
  }
};
</script>

<style lang="less" scoped>
.message-wrap {
  position: absolute;
  z-index: 999;
  left: 230px;
  top: 48px;
  display: flex;
  align-items: center;
  border: 1px solid #fddb94;
  background: #fffbe6;
  padding: 10px;
  width: calc(100% - 232px);

  .message-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 18px;
    height: 18px;
    background: #faad13;
    border-radius: 18px;

    i {
      font-size: 20px;
      color: #fff;
    }
  }

  .content-wrap {
    display: inline-block;
    flex: 1;
    margin: 0px 10px;
    font-weight: 700;
  }

  .close-icon {
    width: 25px;
    text-align: center;
    cursor: pointer;
  }
}
</style>
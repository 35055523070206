<template>
    <div>
        <!-- 列表 -->
        <modal :title="title" v-model="isShow" @on-cancel="closeReset()" :width="width" :footer-hide="true">
            <div class="modal-body">
                <div class="toolbar" v-if="isEdit">
                    <Button custom-icon="iconfont icon-add" class="button" size="small" @click.stop="showAddEditDialog"
                        >新增</Button
                    >
                    <Button
                        v-if="hasModify"
                        custom-icon="iconfont icon-edit"
                        class="button"
                        size="small"
                        :disabled="getSelection() && !getSelection().length"
                        @click.stop="showEditDialog"
                        >修改</Button
                    >
                </div>
                <div class="grid">
                    <Table
                        ref="table"
                        :columns="columns"
                        :data="dataList"
                        :height="gridHeight"
                        :loading="loading"
                        size="small"
                        @on-row-click="rowClick"
                        @on-selection-change="selectionChange"
                    >
                    </Table>
                </div>
            </div>
        </modal>

        <!-- 编辑框 -->
        <sv-edit ref="edit" :title="editTitle" :labelWidth="100">
            <item type="row">
                <item type="col">
                    <item
                        v-for="(item, idx) in editItems"
                        :name="item.name"
                        :label="item.label"
                        :type="item.type"
                        :url="item.url"
                        :min="item.min"
                        :required="item.required"
                        :dataType="item.dataType"
                        :expandLoad="item.expandLoad"
                        :format="item.format"
                        :maxlength="item.maxlength || 100"
                        :key="idx"
                        :hidden="item.hidden"
                    />
                </item>
            </item>
        </sv-edit>

        <!--加载层 -->
        <Spin size="large" fix v-if="loading"></Spin>
    </div>
</template>

<script>
import { request } from '@/network/request';

export default {
    name: 'gridListDialog',
    data() {
        return {
            dataList: [],
            isShow: false,
            loading: false,
            columns: this.getColumns(),
            editItems: this.getEditItems()
        };
    },
    props: {
        title: {
            type: String,
            default: ''
        },

        width: {
            type: Number,
            default: () => 740
        },

        gridHeight: {
            type: Number,
            default: () => 350
        },

        editTitle: {
            type: String,
            default: ''
        },

        readParams: {
            type: Object,
            default: () => {}
        },

        createParams: {
            type: Object,
            default: () => {}
        },

        deleteParams: {
            type: Object,
            default: () => {}
        },

        readUrl: {
            type: String
        },

        createUrl: {
            type: String
        },
        updateUrl: {
            type: String
        },

        deleteUrl: {
            type: String
        },

        isEdit: {
            type: Boolean,
            default: false
        },
        hasDelete: {
            type: Boolean,
            default: true
        },
        hasModify: {
            type: Boolean,
            default: false
        },

        multiSelect: {
            type: Boolean,
            default: false
        },

        rownumber: {
            type: Boolean,
            default: false
        }
    },

    mounted() {
        this.initEvent();
    },

    methods: {
        show() {
            this.isShow = true;
            this.readRecord();
        },

        initEvent() {
            if (this.isEdit) {
                this.$refs.edit.$on('onSubmit', (params, mode, callbck) => {
                    if (mode === 'CREATE') {
                        this.doCreateReocrd(params, callbck);
                    } else if (mode === 'UPDATE') {
                        this.doUpdateRecord(params, callbck);
                    }
                });
            }
        },

        showAddEditDialog() {
            if (this.isEdit) {
                this.$refs.edit.show('CREATE');
            }
        },

        showEditDialog() {
            if (this.getSelection().length) {
                this.$refs.edit.show('UPDATE', this.getSelection()[0]);
            }
        },

        readRecord() {
            const params = this.getPageQueryParams(this.readParams, 1, 999);
            this.loading = true;

            request
                .get(this.readUrl, params)
                .then(data => {
                    this.readRecordSuccess(data);
                    this.loading = false;
                })
                .catch(() => {
                    this.loading = false;
                });
        },

        readRecordSuccess(data) {
            if (data.success) {
                this.dataList = data.list || data.result || [];
            } else {
                this.dataList = [];
                this.$Message.error('查询失败: ' + data.message);
            }
        },

        closeReset() {
            this.isShow = false;

            if (this.isEdit) {
                this.$refs.edit.$off('onSubmit');
            }
        },

        getColumns() {
            let columns = [];
            let items = this.getTagItems('columns');

            this.addSelectionColumn();

            this.addRownumberColumn();

            items.forEach(item => {
                const key = item.data.key;
                const dataType = item.data.attrs.dataType;
                const dateFormat = item.data.attrs.dateFormat;

                if (key) {
                    item.data.attrs['key'] = key;
                }
                if (dataType === 'date') {
                    item.data.attrs['render'] = (h, params) => {
                        let formatDate = this.formatDatetime(params.row[key], dateFormat);
                        return h('span', formatDate);
                    };
                }
                columns.push(item.data.attrs);
            });

            if (this.isEdit && this.hasDelete) {
                const actionColumns = this.getActionColumns();
                columns.push(actionColumns);
            }

            return columns;
        },

        addSelectionColumn(columns) {
            if (this.multiSelect) {
                columns.push({
                    title: '',
                    type: 'selection',
                    width: '40px',
                    align: 'center',
                    fixed: 'left'
                });
            }
        },

        addRownumberColumn(columns) {
            if (this.rownumber) {
                columns.push({
                    title: '序号',
                    type: 'rownumber',
                    width: '80px',
                    align: 'center',
                    render: (h, params) => {
                        return h('span', params.index + 1);
                    }
                });
            }
        },

        getEditItems() {
            let editItems = [];
            let items = this.getTagItems('edit');

            items.forEach(item => {
                editItems.push(item.data.attrs);
            });

            return editItems;
        },

        getTagItems(tag) {
            let items = [];
            let slots = this.$slots.default;

            slots.forEach(item => {
                if (item.tag === tag) {
                    items = item.children || [];
                }
            });

            return items;
        },

        buildColumns(items) {
            const columns = [];
            const actionColumns = this.getActionColumns();

            items.forEach(item => {
                const key = item.key;
                const type = item.type;
                const dateFormat = item.dateFormat;

                if (type === 'rownumber') {
                    item['render'] = (h, params) => {
                        return h('span', params.index + 1);
                    };
                }

                if (type === 'date') {
                    item['render'] = (h, { row }) => {
                        item.width = item.width || (item.dateFormat ? '80px' : '125px;');

                        const formatDate = this.formatDatetime(row[key], dateFormat);
                        return h('span', formatDate);
                    };
                }

                columns.push(item);
            });

            if (this.isEdit) {
                columns.push(actionColumns);
            }

            return columns;
        },
        rowClick(record, idx) {
            this.$refs.table.selectAll(false);

            this.$refs.table.objData[idx]._isChecked = true;
            this.$refs.table.objData[idx]._isHighlight = true;
        },
        selectionChange() {
            let data = this.$refs.table.objData;

            for (let key in data) {
                let item = data[key];
                if (item._isChecked) {
                    data[key]._isHighlight = true;
                } else {
                    data[key]._isHighlight = false;
                }
            }
        },
        getSelection() {
            return this.$refs.table && this.$refs.table.getSelection();
        },
        getActionColumns() {
            return {
                title: '操作',
                key: 'action',
                width: 80,
                align: 'center',
                render: (h, params) => {
                    return h('div', [
                        h(
                            'Icon',
                            {
                                props: {
                                    type: 'ios-trash',
                                    size: '20'
                                },
                                on: {
                                    click: () => {
                                        this.$Modal.confirm({
                                            title: '确认删除当前记录吗?',
                                            onOk: async () => {
                                                this.doDeleteRecord(params);
                                            }
                                        });
                                    }
                                }
                            },
                            '删除'
                        )
                    ]);
                }
            };
        },

        doCreateReocrd(params, callbck) {
            Object.assign(params, this.createParams);

            request
                .post(this.createUrl, params)
                .then(data => {
                    if (data.success) {
                        this.$Message.success('创建成功');
                        this.readRecord();
                        callbck(true);
                    } else {
                        this.$Message.error('创建失败: ' + data.message);
                        callbck(false);
                    }
                    this.loading = false;
                })
                .catch(() => {
                    this.loading = false;
                });
        },
        doUpdateRecord(params, callbck) {
            Object.assign(params, this.createParams);

            request
                .post(this.updateUrl, params)
                .then(data => {
                    if (data.success) {
                        this.$Message.success('更新成功');
                        this.readRecord();
                        callbck(true);
                    } else {
                        this.$Message.error('更新失败: ' + data.message);
                        callbck(false);
                    }
                    this.loading = false;
                })
                .catch(() => {
                    this.loading = false;
                });
        },

        doDeleteRecord(actionRow) {
            let params = { ids: [actionRow.row.id] };

            request
                .post(this.deleteUrl, params)
                .then(data => {
                    if (data.success) {
                        this.readRecord();
                    } else {
                        this.$Message.error('删除失败: ' + data.message);
                    }
                    this.loading = false;
                })
                .catch(() => {
                    this.loading = false;
                });
        }
    }
};
</script>

<style lang="less" scoped>
.modal-body {
    display: flex;
    flex-direction: column;
    button {
        margin: 0 5px;
    }
    .toolbar {
        margin-top: 0px !important;
        padding: 5px;
        justify-content: flex-start !important;
    }
    .grid {
        padding: 5px;
        flex: 1;
    }
}
</style>
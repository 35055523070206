<template>
    <div>
        <Menu
            v-if="data.length > 0 && data[0].level === 1"
            size="small"
            :active-name="activeName"
            :open-names="openedNames"
            width="250"
            ref="menu">
            <div v-for="item in data" :key="item.id">
                <Submenu v-if="item.children.length > 0" :name="item.id" :key="item.id">
                    <template slot="title"><span :class='["iconfont", item.iconCls]'></span>{{ item.text }}</template>
                    <side-menu :data="item.children" />
                </Submenu>
                <MenuItem v-else :name="item.id" :key="item.id" :to="item.to" :target="item.target">
                <span :class='["iconfont", item.iconCls]'></span>{{ item.text }}
                </MenuItem>
            </div>
        </Menu>
        <div v-else>
            <div v-for="item in data" :key="item.id">
                <Submenu v-if="item.children.length > 0" :name="item.id" :key="item.id">
                    <template slot="title"> <span :class='["iconfont", item.iconCls]'></span>{{ item.text }} </template>
                    <side-menu :data="item.children" />
                </Submenu>
                <MenuItem v-else :name="item.id" :key="item.id" :to="item.to" :target="item.target">
                <span :class='["iconfont", item.iconCls]'></span>{{ item.text }}
                </MenuItem>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SideMenu',
    data() {
        return {
            activeName: '',
            openedNames: []
        };
    },
    props: {
        data: {
            type: Array,
            default: () => []
        }
    },
    methods: {
        getOpenedNamesByActiveName(data, name) {
            if (data.id === name) {
                return data.paths;
            }

            for (const item of data.children) {
                const res = this.getOpenedNamesByActiveName(item, name);

                if (res) {
                    return res;
                }
            }
        },

        setOpenedNames(name) {
            const data = { id: 'root', children: this.data };
            this.openedNames = this.getOpenedNamesByActiveName(data, name);

            if (this.$refs.menu) {
                this.$nextTick(() => {
                    this.$refs.menu.updateOpened();
                });
            }
        }
    },
    watch: {
        $route(to) {
            this.activeName = to.name;
        }
    },

    mounted() {
        const name = this.$route.name;
        this.activeName = name;
        this.setOpenedNames(name);
    }
};
</script>

<style></style>

<template>
    <div>
        <Input
            ref="input"
            icon="md-add"
            @on-click="openSelector"
            @dblclick.native="openSelector"
            @on-clear="handleClear"
            placeholder="双击文本框或点击图标选择数据"
            v-model="name"
            :disabled="disabled"
            :title="name"
            readonly
            clearable />
        <modal title="选择数据" v-model="showTree" :mask-closable="false">
            <Tree :data="treeData" show-checkbox @on-check-change="handleCheckChange"></Tree>
            <div slot="footer" class="model-footer-wrap">
                <Button size="large" @click="handleCancel">取消</Button>
                <Button type="primary" size="large" @click="handleConfirm">确认</Button>
            </div>
        </modal>
    </div>
</template>

<script>
import { request } from '@/network/request';
export default {
    props: {
        modelName: {
            type: [Array, String, Number]
        },
        modelCode: {
            type: [Array, String]
        },
        disabled: {
            type: Boolean,
            default: false
        },
        url: {
            type: String
        }
    },
    watch: {
        modelName(val) {
            this.name = val;
        },
        modelCode(val) {
            this.loadTreeData();
            if (!val) {
                this.selectionData = [];
            }
        },
    },
    data() {
        return {
            name: this.modelName,
            treeData: [],
            showTree: false,
            selectionData: []
        };
    },

    mounted() {
        this.loadTreeData();
    },

    methods: {
        loadTreeData() {
            request.get(this.url).then(data => {
                if (data.success) {
                    this.treeData = this.formattingTree(data.result, 'name');
                }
            });
        },
        formattingTree(tree, label) {
            const mytree = tree.map(item => {
                if (item.children && item.children.length) {
                    const chlidren = this.formattingTree(item.children, label);
                    return { code: item.code, title: item[label], children: chlidren, checked: this.modelCode ? this.modelCode.split(',').includes(item.code) : false };
                } else {
                    return { code: item.code, title: item[label], children: [], checked: this.modelCode ? this.modelCode.split(',').includes(item.code) : false };
                }
            });

            return mytree;
        },
        openSelector() {
            if (this.disabled) {
                return;
            }
            this.showTree = true;
        },
        handleClear() {
            this.name = '';
            this.selectionData = [];
            this.$emit('onChange', this.selectionData);
        },
        handleCheckChange(checkList) {
            this.selectionData = checkList;
        },
        handleCancel() {
            this.showTree = false;
        },
        handleConfirm() {
            this.name = this.selectionData.map(item => item.title).join(',');
            this.$emit('onChange', this.selectionData);
            this.showTree = false;
        }
    }
};
</script>

<style lang="less" scoped>
::v-deep .ivu-modal-body {
    height: 400px;
    overflow: auto;

    &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: @body-bg-color;
    }
}

::v-deep .ivu-tree-children {
    li {
        .ivu-tree-title {
            pointer-events: none;
            display: inline-block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

        }
    }
}
</style>
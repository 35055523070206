<template>
    <div class="block-query" :style="{ overflow: isExpand ? 'visible' : 'hidden' }" @dblclick="handleDblClick">
        <div :class="['query-inner', isExpand ? 'bd-shadow' : '']" :style="{ height: isExpand ? 'auto' : '53px' }">
            <div class="form-wrap">
                <Form
                    :label-width="labelWidth"
                    :model="dataModel"
                    :rules="validateModel"
                    ref="searchForm"
                    inline
                    label-position="right"
                    onsubmit="return false">
                    <FormItem
                        v-for="item in this.$slots.default"
                        :key="item.data.attrs.name"
                        :label="item.data.attrs.label + ':'"
                        :prop="item.data.attrs.name"
                        v-show="item.data.attrs.hidden != true"
                        :class="columnClass">
                        <div @dblclick.stop>
                            <!-- 文本框 -->
                            <Input
                                v-if="item.data.attrs.type === 'textbox'"
                                v-model="dataModel[item.data.attrs.name]"
                                :placeholder="computePlaceholder(item)"
                                :maxlength="item.data.attrs.maxlength"
                                :disabled="item.data.attrs.relyon && !dataModel[item.data.attrs.relyon]"
                                @on-keyup="doEnterSearch"
                                clearable />

                            <sv-vin-input
                                v-if="item.data.attrs.type === 'vin'"
                                v-model="dataModel[item.data.attrs.name]"
                                :placeholder="computePlaceholder(item)"
                                :maxlength="item.data.attrs.maxlength"
                                @on-keyup="doEnterSearch"
                                @vin-click="handleVinClick(dataModel[item.data.attrs.name])" />

                            <!-- 日历框 -->
                            <DatePicker
                                v-if="item.data.attrs.type === 'datebox'"
                                v-model="dataModel[item.data.attrs.name]"
                                :type="item.data.attrs.dateType ? item.data.attrs.dateType : 'date'"
                                :format="item.data.attrs.format ? item.data.attrs.format : 'yyyy-MM-dd'"
                                clearable />

                            <!-- 下拉框 -->
                            <SvCombobox
                                v-if="item.data.attrs.type === 'combobox'"
                                v-model="dataModel[item.data.attrs.name]"
                                :url="item.data.attrs.url"
                                :autoLoad="item.data.attrs.autoLoad"
                                :expandLoad="item.data.attrs.expandLoad"
                                :dependParamsKey="item.data.attrs.dependParamsKey"
                                :dependParams="item.data.attrs.dependParams"
                                :clearTarget="item.data.attrs.clearTarget"
                                :multiSelect="item.data.attrs.multiSelect"
                                :group="item.data.attrs.group"
                                :displayFormat="item.data.attrs.displayFormat" />
                            <!-- 开关 -->
                            <div v-if="item.data.attrs.type === 'switch'" :style="'width:' + boxWidth + 'px'">
                                <i-switch
                                    v-model="dataModel[item.data.attrs.name]"
                                    :true-value="item.data.attrs.trueValue"
                                    :false-value="item.data.attrs.falseValue">
                                    <span slot="open">是</span>
                                    <span slot="close">否</span>
                                </i-switch>
                            </div>
                        </div>
                    </FormItem>
                </Form>
            </div>
            <div class="btns">
                <Button @click="doReset">重置</Button>
                <Button @click="doSearch" type="primary">查询</Button>
                <div v-if="hasMore">
                    <span title="双击空白区域可展开/收起" class="btn" @click="toggleExpand" v-if="!isExpand">展开
                        <Icon type="ios-arrow-down" />
                    </span>
                    <span title="双击空白区域可展开/收起" class="btn" @click="toggleExpand" v-if="isExpand">
                        收起
                        <Icon type="ios-arrow-up" />
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        labelWidth: {
            type: Number,
            default: () => 120
        },
        boxWidth: {
            type: Number,
            default: () => 135
        },
        hasMore: {
            type: Boolean,
            default: true
        },
        expand: {
            type: Boolean,
            default: true
        }
    },
    mounted() {
        if (this.$route.query.hasParams) {
            this.doSearch();
        }
        window.onresize = () => {
            this.columnClass = this.getColumnClass();
        };
    },
    data() {
        return {
            isExpand: this.expand,
            dataModel: this.getDataModel(),
            validateModel: this.getValidateModel(),
            columnClass: this.getColumnClass(),
        };
    },
    methods: {
        /**
         * 回车键实现查询
         */
        doEnterSearch: function (e) {
            if (e.keyCode === 13) {
                this.doSearch();
            }
        },

        /**
         * 实现查询
         */
        doSearch: async function () {
            let isNext = true;
            const isValid = await this.$refs.searchForm.validate();

            if (!isValid) {
                this.$Message.error('查询条件必填项不能为空!');
                return;
            }

            const params = this.getParams();

            // 查询之前钩子
            this.$emit('onBeforeSearch', params, function (result) {
                isNext = result;
            });

            if (isNext) {
                this.$emit('onSearch', params);
            }
        },

        /**
         * 重置查询条件
         */
        doReset: function () {
            this.$refs.searchForm.resetFields();
        },

        setFieldsValue(data) {
            for (const key in data) {
                this.dataModel[key] = data[key];
            }
        },

        /**
         * 获取查询字段模型
         */
        getDataModel() {
            let dataModel = {};
            const slots = this.$slots.default;

            slots.forEach(function (item) {
                const name = item.data.attrs.name;
                const value = item.data.attrs.value;

                if (value) {
                    dataModel[name] = value;
                } else if (value === false) {
                    dataModel[name] = value;
                } else {
                    dataModel[name] = null;
                }
            });

            return dataModel;
        },

        /**
         * 获取验证模型
         */
        getValidateModel() {
            let validateModel = {};
            let slots = this.$slots.default;

            slots.forEach(function (item) {
                let attrs = item.data.attrs;

                if (attrs.required === 'true') {
                    validateModel[attrs.name] = [
                        {
                            required: true,
                            message: ' ',
                            trigger: 'blur',
                            type: attrs.type == 'datebox' ? 'date' : 'string'
                        }
                    ];
                }
            });

            return validateModel;
        },

        /**
         * 获取查询参数
         */
        getParams: function () {
            const params = {};
            const fields = this.$refs.searchForm.fields;

            fields.forEach(item => {
                const opts = item.$children[0].$options;
                const type = opts.propsData.type;
                const prop = item.prop;
                const val = item.fieldValue;

                if (!this.isEmpty(val)) {
                    if (type === 'date') {
                        params[prop] = this.formatDatetime(val, 'UTC');
                    } else if (type === 'month') {
                        params[prop] = this.formatDatetime(val, 'YYYYMM');
                    } else if (typeof val === 'string') {
                        params[prop] = val;
                    } else {
                        params[prop] = val;
                    }
                }
            });

            return params;
        },

        /**
         * 查询切换展开
         */
        toggleExpand() {
            if (this.hasMore) {
                this.isExpand = !this.isExpand;
                this.$nextTick(() => {
                    this.$emit('onExpand');
                });
            }
        },

        /**
         * 处理双击展开收起
         */
        handleDblClick() {
            this.toggleExpand();
        },

        /**
         * 计算placeholder
         */
        computePlaceholder(item) {
            return (
                (item.data.attrs.relyon && !this.dataModel[item.data.attrs.relyon] && item.data.attrs.relyNotice) ||
                item.data.attrs.placeholder
            );
        },

        /**
         * 处理vin点击
         */
        handleVinClick(value) {
            this.$emit('vin-click', value);
        },

        getColumnClass() {
            const screenWidth = document.body.clientWidth;

            if (screenWidth < 1200) {
                return 'column-width-12';
            } else if (screenWidth < 1600) {
                return 'column-width-8';
            } else {
                return 'column-width-6';
            }
        },
    }
};
</script>

<style lang="less">
.block-query {
    border-radius: 5px;

    * {
        user-select: none;
    }

    .query-inner {
        padding: 18px;
        border-radius: @border-radius-size;
        display: flex;
        flex-direction: row;
        background: #fff;
        z-index: 50;
    }

    .form-wrap {
        flex: 1;
    }

    .btns {
        width: 200px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        align-self: flex-start;
        margin-left: 10px;

        .ivu-btn {
            margin-right: 10px;
        }

        .btn {
            color: @primary-color;
            display: flex;
            align-items: center;

            i {
                font-size: 16px;
                margin-left: 3px;
            }
        }
    }
}

.ivu-form-item-content {
    position: static;
}

.ivu-form-item-error-tip {
    position: static;
}
</style>

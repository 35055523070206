import hasPermission from './permission';
import defaultImg from './defaultImg';

const directives = {
    install: function(Vue) {
        Vue.directive('permission', hasPermission);
        Vue.directive('defaultImg', defaultImg);
    }
};

export default directives;
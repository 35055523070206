// 模板
<template>
    <modal :title="title" v-model="isShow" :width="width" @on-cancel="doClose">
            <Row v-for="(item1, idx) in this.$slots.default" :key="'row' + idx">
                <Col
                    v-for="(item2, idx) in item1.children"
                    :key="'col' + idx"
                    :span="item2.data.attrs.span || 24"
                    :style="item2.data.attrs.colStyle"
                    :class-name="'col-wrap' + ' ' + item2.data.attrs.colCls"
                >
                    <Row>
                        <Col
                            v-for="(item3, idx) in item2.children"
                            :key="'cmp-' + idx"
                            :set="(attrs = (item3.data && item3.data.attrs) || {})"
                            :span="item3.data.attrs.span || 24"
                        >
                           <sv-display-field :labelWidth="labelWidth" :label="item3.data.attrs.label" :text="dataModel[item3.data.attrs.name]"/>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Form>

        <div slot="footer">
            <Button type="text"  @click="doClose()">关闭</Button>
        </div>
    </modal>
</template>

// 脚本
<script>
const COMPS_TYPES = ['displayfield'];

export default {
    name: 'SvDetail',

    data: function () {
        return {
            loading: false,
            isShow: false,
            dataModel: this.getDataModel()
        };
    },

    props: {
        title: {
            type: String
        },
        width: {
            type: Number,
            default: () => 520
        },
        labelWidth: {
            type: String,
            default: () => '110px'
        }
    },

    methods: {
        show(record) {
            this.isShow = true;
            this.setRecord(record);
        },

        setRecord(record) {
            if (record) {
                for (let key in this.dataModel) {
                    const item = this.findFormItems(key);
                    const val = record[key];

                    if (item.dataType === 'date') {
                        this.dataModel[key] = this.formatDatetime(val, 'YYYY-MM-DD');
                    } else if (item.dataType === 'number') {
                        if (this.isEmpty(val)) {
                            this.dataModel[key] = 0;
                        } else {
                            this.dataModel[key] = val;
                        }
                    } else {
                        this.$set(this.dataModel, key, val);
                    }
                }
            }
        },

        getDataModel() {
            let dataModel = {};
            const items = this.getFormItems(this.$slots.default);

            items.forEach(item => {
                const name = item.data.attrs.name;
                const value = item.data.attrs.value;
                const dataType = item.data.attrs.dataType;

                if (dataType === 'number') {
                    if (this.isEmpty(value)) {
                        dataModel[name] = 0;
                    } else {
                        dataModel[name] = value;
                    }
                } else {
                    if (this.isEmpty(value)) {
                        dataModel[name] = '';
                    } else {
                        dataModel[name] = value;
                    }
                }
            });

            return dataModel;
        },

        getFormItems(data) {
            let nodes = [];

            data.forEach(item => {
                let subNodes = [];
                let type = item.data.attrs.type;

                if (item.children && item.children.length) {
                    subNodes = this.getFormItems(item.children);
                }
                if (subNodes.length > 0) {
                    nodes = nodes.concat(subNodes);
                } else if (COMPS_TYPES.indexOf(type) > -1) {
                    nodes.push(item);
                }
            });

            return nodes;
        },

        findFormItems(name) {
            let attrs = null;
            let items = this.getFormItems(this.$slots.default);

            items.forEach(item => {
                if (item.data.attrs.name === name) {
                    attrs = item.data.attrs;
                }
            });

            return attrs;
        },

        doClose() {
            this.isShow = false;
            this.loading = false;
            this.doReset();
        },

        doReset() {
            for (let key in this.dataModel) {
                this.dataModel[key] = '';
            }
        }
    }
};
</script>

// 样式
<style lang="less" scoped>
/deep/ .col-wrap {
    padding: 0px;
}
</style>
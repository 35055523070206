export default [{
    path: '/test',
    meta: {
        title: '测试页面',
        notAuth: true
    },
    component: () =>
        import ('@/pages/test/index')
}, {
    path: '*',
    meta: {
        title: '页面不存在',
        notAuth: true
    },
    component: () =>
        import ('@/pages/errorPages/404')
}];
<template>
    <div class="sv-textarea-field">
        <span class="label">{{ label }}：</span>
        <span class="text" v-html="text"></span>
    </div>
</template>

<script>
export default {
    props: ['label', 'text']
};
</script>

<style lang="less" scoped>
@import '~@/less/sdk/index';

.sv-textarea-field {
    display: flex;
    flex-direction: row;
    font-size: 12px;
    padding: 5px 0;
    line-height: 18px;

    .label {
        display: inline-block;
        color: @lightest-font-color;
        width: 110px;
        text-align: right;
    }

    .text {
        padding: 4px 7px;
        height: 100px;
        flex: 1;
        border: 1px solid #dcdee2;
        color: @font-color;
        border-radius: @border-radius-size;
        word-break: break-all;
        overflow: auto;
    }
}
</style>

<template>
    <div class="upload">
        <div>
            <Upload
                ref="uploadfile"
                :data="data"
                :headers="headers"
                :format="format"
                :action="baseURL + url"
                :on-progress="onProgress"
                :show-upload-list="showUploadList"
                :on-format-error="onFormatError"
                :on-success="onSuccess"
                :on-error="onError"
                :maxSize="maxSize"
                :multiple="multiple"
                :on-exceeded-size="onExceededSize"
                :name="name">
                <Button :icon="icon" :size="btnUploadSize" :loading="btnUploadLoading">{{ btnUplodText }}</Button>
            </Upload>
        </div>
        <div class="progress" v-if="showProgress">
            <Progress :percent="percent" status="active" />
        </div>
    </div>
</template>
<script>
import { storage } from '@/common/storage';
import config from 'config';

export default {
    name: 'svUpload',

    data: function () {
        return {
            baseURL: config.baseURL,
            percent: 0,
            showProgress: false
        };
    },

    props: {
        icon: {
            type: String,
            default: ''
        },

        // 上传文件的url
        url: {
            type: String,
            default: () => ''
        },

        // 上传请求头部
        headers: {
            type: Object,
            default: () => {
                const token = storage.getToken();

                return { Authorization: `Bearer ${token}` };
            }
        },

        // 是否多个上传
        multiple: {
            type: Boolean,
            default: () => false
        },

        // 上传字段名称
        name: {
            type: String,
            default: () => 'files'
        },

        // 上传其他字段信息
        data: {
            type: Object,
            default: () => { }
        },

        // 上传loading
        btnUploadLoading: {
            type: Boolean,
            default: () => false
        },

        // 浏览文件按钮文本
        btnUplodText: {
            type: String,
            default: () => '浏览'
        },

        // 上传按钮大小
        btnUploadSize: {
            type: String,
            default: () => 'small'
        },

        // 是否显示上列表
        showUploadList: {
            type: Boolean,
            default: () => false
        },

        // 上传文件之前
        beforeUpload: {
            type: Function
        },

        // 上传文件格式
        format: {
            type: Array,
            default: () => []
        },
        maxSize:{
          type: Number
        }
    },

    methods: {
        /**
         * 上传进度
         * @param {Object} e
         */
        onProgress(e) {
            this.showProgress = true;
            this.percent = new Number(e.percent.toFixed(0))
        },
        /**
         * 清除文件
         */
        clearFiles() {
            this.$refs.uploadfile.clearFiles();
        },

        /**
         * 上传文件提交
         * @param {Object} file
         */
        post(file) {
            this.$refs.uploadfile.post(file);
        },

        /**
         * 成功处理
         * @param {Object} response
         * @param {Object} file
         * @param {Object} fileList
         */
        onSuccess(response, file, fileList) {
            this.percent = 0;
            this.showProgress = false;
            this.$emit('onSuccess', response, file, fileList);
        },

        /**
         * 错误处理
         * @param {Object} error
         * @param {Object} file
         * @param {Object} fileList
         */
        onError(error, file, fileList) {
            switch (error.status) {
                case 401:
                    this.$Notice.error({
                        title: '提示',
                        desc: error.data.header.message
                    });
                    break;
                default:
                    this.$Notice.error({
                        title: '提示',
                        desc: '接口访问失败，请确认网络是否正常，如仍有问题，请与管理员联系。'
                    });
                    break;
            }

            this.$emit('onError', error, file, fileList);
        },
        onExceededSize(){
          this.$Message.error('文件超出最大上传限制!');
        },

        onFormatError() {
            this.$Message.error('文件格式不正确，请重新上传');
        }
    }
};
</script>
<style lang="less">
.upload {
    position: relative;

    .progress {
        position: absolute;
        width: 100px;
    }
}
</style>
<template>
    <div class="sv-input-field" @click.stop>
        <span class="label" :style="{ width: labelWidth }">{{ label }}：</span>
        <span class="text">
            <!-- 文本框 -->
            <Input
                v-if="type == 'textbox'"
                :placeholder="placeholder"
                v-model="model"
                @on-change="hanleChange"
                :maxlength="maxlength"
                :clearable="clearable"
            />

            <!-- 数字框 -->
            <InputNumber
                :max="99999"
                :min="0"
                v-model="model"
                v-if="type == 'numberbox'"
                @on-change="hanleChange"
                :placeholder="placeholder"
            />
        </span>
    </div>
</template>

<script>
export default {
    props: {
        type: {
            type: String,
            default: 'textbox'
        },
        label: {
            type: String
        },
        url: {
            type: String
        },
        labelWidth: {
            type: String,
            default: '80px'
        },
        maxlength: {
            type: [String, Number]
        },
        value: {
            type: [String, Number]
        },
        clearable: {
            type: Boolean,
            default: true
        },
        placeholder: {
            type: String
        }
    },
    data() {
        return {
            model: this.value
        };
    },
    watch: {
        value(value) {
            this.model = value;
        }
    },
    methods: {
        hanleChange(e) {
            const { value } = e.target;

            this.$emit('input', value);
            this.$emit('on-change', value);
        }
    }
};
</script>

<style lang="less" scoped>
@import '~@/less/sdk/index';

.sv-input-field {
    display: flex;
    flex-direction: row;
    font-size: 12px;
    padding: 5px 0;
    line-height: 18px;

    .label {
        display: inline-block;
        line-height: 28px;
        color: @lightest-font-color;
        text-align: right;
    }

    .text {
        flex: 1;
        color: @font-color;
    }
}
</style>

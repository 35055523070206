import { request } from "@/network/request";
import { storage } from "@/common/storage";

// 手册类型
const MANUAL_TYPE = {
    'services-manual': 'maintain_manual',
    'circuit-diagram': 'circuit',
    'split-manual': 'split-manual',
    'manhour': 'manhour'
};

// 操作行为
const BEHAVIOR_TYPE = {
    'A': 'tis_click',
    'B': 'tis_collect'
};

// 添加额外的参数
function appendExtraParams(params) {
    const userInfo = storage.getUserInfo();

    params['userId'] = userInfo.accountId;
    params['userName'] = userInfo.userName;
    params['behaviorType'] = BEHAVIOR_TYPE[params.behaviorType] || '';
    params['moduleType'] = MANUAL_TYPE[params.moduleType] || '';

    return params;
}

/**
 * @param {Object} => {userId, userName, moduleType, manualPath, catalogType, itemId, itemName, behaviorType}
 * @description '跟踪用户操作行为'
 */
export function track(params) {
    const url = '/proxy_das/behavior/record?scope=tis';
    const newParams = appendExtraParams(params);

    request.post(url, newParams).then((data) => {
        if (!data.success) {
            console.log('track error:' + data.message || data.msg);
        }
    });
}
<template>
    <div class="header-bar-wrap">
        <!-- 头部左边 -->
        <div :class="{ 'header-logo-block': true, 'mini-header': !isExpand }">
            <a class="logo-wrap">
                <img src="@/assets/images/logo.png" alt="" />
            </a>
        </div>

        <!-- 头部右边区域 -->
        <div class="header-right-block">
            <div>
                <!-- 菜单切换图标 -->
                <span class="toggle-wrap">
                    <i :class="getToggleClass()" @click="handleToggleMenu" />
                </span>

                <!-- 系统名称 -->
                <span class="sys-title">TIS统一平台管理中心</span>
            </div>

            <!-- 头部工具栏 -->
            <div class="header-tbar-wrap">
                <!-- tis用户端 -->
                <span class="header-icon-wrap" @click="openTis">
                    <span>
                        <i class="iconfont icon-tis-clinet-side" />
                    </span>
                    <span class="icon-text">TIS用户端</span>
                </span>

                <!-- 分隔线 -->
                <span class="header-line"></span>

                <!-- 设置 -->
                <span class="header-icon-wrap">
                    <span>
                        <i class="iconfont icon-settings" />
                    </span>
                    <span class="icon-text">设置</span>
                </span>

                <!-- 分隔线 -->
                <span class="header-line"></span>

                <!-- 用户名 -->
                <Dropdown placement="bottom-end">
                    <span class="header-icon-wrap">
                        <span class="user-icon-wrap">
                            <i class="iconfont icon-user" />
                        </span>
                        <span class="icon-text">{{ username }}</span>
                        <!-- <Icon type="ios-arrow-down"></Icon> -->
                    </span>
                    <!-- <DropdownMenu slot="list">
                        <DropdownItem @click.native="handleLogout">退出登录</DropdownItem>
                    </DropdownMenu> -->
                </Dropdown>
            </div>
        </div>
    </div>
</template>

<script>
import { storage } from '@/common/storage';
import config from 'config';
// import { request } from '@/network/request';

export default {
    data() {
        const userInfo = storage.getUserInfo();
        return {
            isExpand: true,
            username: userInfo.username || '测试用户'
        };
    },

    created() {},

    methods: {
        getToggleClass() {
            return this.isExpand ? 'iconfont icon-toggle-off' : 'iconfont icon-toggle-on';
        },

        handleToggleMenu() {
            this.isExpand = !this.isExpand;

            this.$eventBus.$emit('onExpandMenu', this.isExpand);
        },

        openTis() {
            window.open(config.tisURL + '/home');
        }

        // 处理退出
        // handleLogout() {
        //     this.$Modal.confirm({
        //         title: '您确认要退出当前系统吗?',
        //         onOk: async () => {
        //             this.doLogout();
        //         }
        //     });
        // },

        // 执行退出
        // doLogout() {
        //     request.get('/sso/user/logout').then(data => {
        //         if (data.success) {
        //             storage.removeStorage();
        //             window.location.href = data.result.loginUrl;
        //         } else {
        //             this.$Message.error(data.msg);
        //         }
        //     });
        // }
    }
};
</script>

<style lang="less" scoped>
@import '~@/less/sdk/index';

.header-bar-wrap {
    display: flex;

    .header-logo-block {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 255px;
        height: 48px;
        padding: 0px 3px;
        background: @primary-color;
        border-bottom: 1px solid #4390d4;

        .logo-wrap {
            display: inline-block;
            height: 100%;
            object-fit: scale-down;
            padding: 5px 0px;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }

    .mini-header {
        width: 53px;
    }

    .header-right-block {
        display: flex;
        flex: 1;
        justify-content: space-between;
        align-items: center;
        height: 48px;
        background-color: #fff;

        .toggle-wrap {
            margin-left: 10px;

            i {
                color: #86909c;
                cursor: pointer;
                font-size: 13px;
            }
        }

        .sys-title {
            font-size: 18px;
            font-weight: 700;
            margin-left: 15px;
        }

        .header-tbar-wrap {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-right: 10px;

            .header-icon-wrap {
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;

                i {
                    font-size: 13px;
                }

                .user-icon-wrap {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 28px;
                    height: 28px;
                    border-radius: 28px;
                    background-color: #dbdbdb;

                    i {
                        color: #fff;
                    }
                }

                .icon-text {
                    margin-left: 8px;
                    font-size: 13px;
                    color: #000000;
                    font-weight: 500;
                }
            }

            .header-line {
                display: inline-block;
                height: 12px;
                width: 0px;
                border: 1px solid #ddd;
                margin: 0px 15px;
            }
        }
    }
}
</style>

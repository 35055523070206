import Vue from 'vue';
import {
    formatDatetime,
    formatQueryParams,
    getPageQueryParams,
    isEmpty,
    blobExport,
    formatAmount,
    formatMileage,
    parseForamt,
    getLastMonth,
    getDebounce,
    spliceResPath,
    appendTokenToUrl,
    track
} from '@/common';

// 注入全部方法
const methods = {
    install(Vue) {
        Vue.prototype.formatDatetime = formatDatetime;
        Vue.prototype.getPageQueryParams = getPageQueryParams;
        Vue.prototype.formatQueryParams = formatQueryParams;
        Vue.prototype.isEmpty = isEmpty;
        Vue.prototype.blobExport = blobExport;
        Vue.prototype.formatAmount = formatAmount;
        Vue.prototype.formatMileage = formatMileage;
        Vue.prototype.parseForamt = parseForamt;
        Vue.prototype.getLastMonth = getLastMonth;
        Vue.prototype.getDebounce = getDebounce;
        Vue.prototype.spliceResPath = spliceResPath;
        Vue.prototype.appendTokenToUrl = appendTokenToUrl;
        Vue.prototype.track = track;
    }
};

Vue.use(methods);
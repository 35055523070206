// 模板
<template>
    <div>
        <modal :title="dialogTitle" v-model="isShow" @on-cancel="closeReset()" :width="width" :mask-closable="false">
            <div class="modal-body">
                <div class="search" :set="(searchItems = this.getTagItems('search'))">
                    <Form ref="searchForm" :label-width="labelWidth" :model="searchModel" @submit.native.prevent>
                        <FormItem
                            v-for="(item, idx) in searchItems"
                            :key="idx"
                            :label="item.data.attrs.label + ':'"
                            :prop="item.data.attrs.name">
                            <!-- 文本框 -->
                            <Input
                                v-if="item.data.attrs.type === 'textbox'"
                                v-model="searchModel[item.data.attrs.name]"
                                :placeholder="item.data.attrs.placeholder"
                                @on-keyup="doEnterSearch"
                                :maxlength="item.data.attrs.maxlength"
                                :style="'width:' + boxWidth + 'px'"
                                clearable />

                            <!-- 日历框 -->
                            <DatePicker
                                v-if="item.data.attrs.type === 'datebox'"
                                v-model="searchModel[item.data.attrs.name]"
                                :type="item.data.attrs.dateType ? item.data.attrs.dateType : 'date'"
                                :format="item.data.attrs.format ? item.data.attrs.format : 'yyyy-MM-dd'"
                                :style="'width:' + boxWidth + 'px'"
                                clearable />

                            <!-- 下拉框 -->
                            <SvCombobox
                                v-if="item.data.attrs.type === 'combobox'"
                                v-model="searchModel[item.data.attrs.name]"
                                :url="item.data.attrs.url"
                                :dependParams="item.data.attrs.dependParams"
                                :targetFields="item.data.attrs.targetFields"
                                :multiSelect="item.data.attrs.multiSelect"
                                :style="'width:' + boxWidth + 'px'"
                                :group="item.data.attrs.group" />
                        </FormItem>
                    </Form>
                    <div class="search-button">
                        <Button @click="doReset">重置</Button>
                        <Button type="primary" @click="doSearch">查询</Button>
                    </div>
                </div>
                <div class="grid">
                    <Table
                        ref="table"
                        :columns="columns"
                        :data="dataList"
                        :height="gridHeight"
                        :loading="showLoading"
                        @on-row-click="handleSelectionRow"
                        @on-select-all="handleSelectAll"
                        @on-select-all-cancel="handleSelectAllCancel"
                        @on-select="handleSelect"
                        @on-select-cancel="handleSelectCancel"
                        @on-selection-change="handleSelectionChange"
                        size="small"
                        highlight-row></Table>
                </div>
                <div class="paging">
                    <Page
                        :total="tableDataPage.total"
                        class="pull-right margin-top"
                        transfer
                        show-elevator
                        show-sizer
                        show-total
                        size="small"
                        :current="tableDataPage.page"
                        :pageSize="tableDataPage.size"
                        :pageSizeOpts="tableDataPage.pageSizeOpts"
                        @on-change="gotoPage"
                        @on-page-size-change="pageSizeOnChange" />
                </div>
            </div>

            <div slot="footer">
                <Button type="text" @click="closeReset()">关闭</Button>
                <Button type="primary" v-if="isShowConfirmButton" @click="handleConfirm">确认</Button>
            </div>
        </modal>
    </div>
</template>

// 脚本
<script>
import { request } from '@/network/request';

export default {
    name: 'SvSelectorGrid',
    data() {
        return {
            dataList: [],
            selectionData: [],
            isShow: false,
            showLoading: false,
            rowClickCount: 0,
            columns: this.getColumns(),
            searchModel: this.getSearchModel(),
            extraParams: {},
            dialogTitle: this.title,
            tableDataPage: {
                page: 1,
                size: 20,
                total: 0,
                pageSizeOpts: [10, 20, 50, 100]
            }
        };
    },
    props: {
        url: {
            type: String,
            default: ''
        },

        title: {
            type: String
        },

        keyName: {
            type: [String, Array],
            default: 'code'
        },

        width: {
            type: Number,
            default: 800
        },

        boxWidth: {
            type: Number,
            default: () => 150
        },

        gridHeight: {
            type: Number,
            default: 450
        },

        labelWidth: {
            type: Number,
            default: 60
        },

        model: {
            type: String
        },

        multiSelect: {
            type: Boolean,
            default: () => false
        },

        isShowConfirmButton: {
            type: Boolean,
            default: () => true
        },

        rownumber: {
            type: Boolean,
            default: () => false
        }
    },

    methods: {
        show(selectionData, extraParams, title) {
            this.selectionData = selectionData || [];
            this.extraParams = extraParams || {};
            this.isShow = true;
            this.rowClickCount = 0;
            if (title) {
                this.dialogTitle = title;
            }
            this.readRecord();
        },

        handleSelectAll(selection) {
            for (let item of selection) {
                this.addSelectionItem(item);
            }
        },

        handleSelectAllCancel() {
            for (let item of this.dataList) {
                this.removeSelectionItem(item);
            }
        },

        handleSelect(selection, row) {
            this.addSelectionItem(row);
        },

        handleSelectCancel(selection, row) {
            this.removeSelectionItem(row);
        },

        handleSelectionChange() {
            let data = this.$refs.table.objData;

            for (let key in data) {
                let item = data[key];
                data[key]._isHighlight = item._isChecked;
            }
        },

        handleConfirm() {
            this.$emit('confirmSelection', this.selectionData);
            this.closeReset();
        },

        handleSelectionRow(record) {
            if (!this.multiSelect && this.rowClickCount == 0) {
                this.rowClickCount++;
                this.$emit('selectionRow', record);
            }
        },

        doReset: function () {
            this.$refs.searchForm.resetFields();
        },

        doEnterSearch: function (e) {
            if (e.keyCode === 13) {
                this.doSearch();
            }
        },

        addSelectionItem(row) {
            let idx = this.getSelectionItemIdx(row);

            if (idx === -1) {
                this.selectionData.push(row);
            }
        },

        removeSelectionItem(row) {
            let idx = this.getSelectionItemIdx(row);

            if (idx > -1) {
                this.selectionData.splice(idx, 1);
            }
        },

        getSelectionItemIdx(row) {
            let idx = -1;
            const keyContent1 = this.getKeyContent(row);

            for (let i = 0; i < this.selectionData.length; i++) {
                const item = this.selectionData[i];
                const keyContent2 = this.getKeyContent(item);

                if (keyContent1 === keyContent2) {
                    return i;
                }
            }

            return idx;
        },

        getKeyContent(row) {
            if (Array.isArray(this.keyName)) {
                const keyContent = [];

                this.keyName.forEach(key => {
                    keyContent.push(row[key]);
                });

                return keyContent.join(',');
            } else {
                return row[this.keyName];
            }
        },

        doSearch() {
            this.tableDataPage.page = 1;

            this.readRecord();
        },

        readRecord() {
            const params = this.getReadParams();
            this.showLoading = true;
            request
                .get(this.url, params)
                .then(data => {
                    this.readRecordSuccess(data);
                    this.showLoading = false;
                })
                .catch(() => {
                    this.showLoading = false;
                });
        },

        readRecordSuccess(data) {
            if (data.success) {
                this.dataList = data.result.list;
                this.tableDataPage.total = parseInt(data.result.total) || 0;
                this.$nextTick(() => {
                    this.setSelectionChecked();
                });
            } else {
                this.tableDataPage.total = 0;
                this.dataList = [];
                this.$Message.error('查询失败: ' + data.message);
            }
        },

        setSelectionChecked() {
            for (let key in this.selectionData) {
                const item = this.selectionData[key];
                const keyContent = this.getKeyContent(item);
                this.setRowChecked(keyContent);
            }
        },

        setRowChecked(keyContent) {
            let objData = this.$refs.table.objData;

            for (let key in objData) {
                let item = objData[key];

                if (this.getKeyContent(item) === keyContent) {
                    item['_isChecked'] = true;
                    item['_isHighlight'] = true;
                }
            }
        },

        getReadParams() {
            const pageSize = this.tableDataPage.size;
            const curPageIndex = this.tableDataPage.page || 1;
            const filterParams = this.getFilterParams();
            const queryParams = this.formatQueryParams(filterParams, curPageIndex, pageSize);

            return 'args=' + encodeURIComponent(JSON.stringify(queryParams));
        },

        getFilterParams: function () {
            const params = {};
            const fields = this.$refs.searchForm.fields;

            fields.forEach(item => {
                const opts = item.$children[0].$options;
                const type = opts.propsData.type;
                const prop = item.prop;
                const val = item.fieldValue;

                if (!this.isEmpty(val)) {
                    if (type === 'date') {
                        params[prop] = this.formatDatetime(val, 'UTC');
                    } else if (type === 'month') {
                        params[prop] = this.formatDatetime(val, 'YYYYMM');
                    } else {
                        params[prop] = val;
                    }
                }
            });

            return { ...params, ...this.extraParams };
        },

        closeReset() {
            this.isShow = false;
            this.selectionData = [];
            this.tableDataPage.page = 1;
            this.$refs.searchForm.resetFields();
        },

        gotoPage(page) {
            this.tableDataPage.page = page;
            this.readRecord();
        },

        pageSizeOnChange(size) {
            this.tableDataPage.page = 1;
            this.tableDataPage.size = size;
            this.readRecord();
        },

        clearSelectionAll() {
            this.$refs.table.selectAll(false);
        },

        getColumns() {
            let columns = [];
            let items = this.getTagItems('columns');

            this.addSelectionColumn(columns);

            this.addRownumberColumn(columns);

            items.forEach(item => {
                if (!item.data) return;
                const key = item.data.key;
                const { type, dateFormat, linkUrl } = item.data.attrs;

                if (key) {
                    item.data.attrs['key'] = key;
                }
                if (type === 'date') {
                    item.data.attrs.width = item.data.attrs.width || (dateFormat ? '80px' : '125px;');
                    item.data.attrs['render'] = (h, params) => {
                        let formatDate = this.formatDatetime(params.row[key], dateFormat);
                        return h('span', formatDate);
                    };
                }
                if (linkUrl) {
                    item.data.attrs['render'] = (h, params) => {
                        const urls = linkUrl.split('?');
                        const paramsKey = urls[1];

                        return h('a', {
                            domProps: {
                                href: urls[0] + '?' + paramsKey + '=' + params.row[paramsKey],
                                target: '_blank',
                                innerHTML: params.row[key]
                            }
                        });
                    };
                }

                item.data.attrs.tooltip = true;

                columns.push(item.data.attrs);
            });

            // if (columns.length > -1) {
            //     columns[columns.length - 1]['flex'] = 1;
            //     delete columns[columns.length - 1].width;
            // }

            return columns;
        },

        addRownumberColumn(columns) {
            if (this.rownumber) {
                columns.push({
                    title: '序号',
                    width: '80px',
                    align: 'center',
                    render: this.getRowNumberRender()
                });
            }
        },

        addSelectionColumn(columns) {
            if (this.multiSelect) {
                columns.push({
                    title: '',
                    type: 'selection',
                    width: '40px',
                    align: 'center',
                    fixed: 'left'
                });
            }
        },

        getRowNumberRender() {
            return (h, { index }) => {
                return h('span', index + (this.tableDataPage.page - 1) * this.tableDataPage.size + 1);
            };
        },

        getSearchModel() {
            let dataModel = {};
            const slots = this.getTagItems('search');

            slots.forEach(function (item) {
                const name = item.data.attrs.name;
                const value = item.data.attrs.value;

                if (value) {
                    dataModel[name] = value;
                } else {
                    dataModel[name] = null;
                }
            });

            return dataModel;
        },

        getSelection() {
            return this.$refs.table.getSelection();
        },

        getTagItems(tag) {
            let items = [];
            const slots = this.$slots.default;

            slots.forEach(item => {
                if (item.tag === tag) {
                    items = item.children || [];
                }
            });

            return items;
        }
    }
};
</script>

<style lang="less" >
.modal-body {
    display: flex;
    flex-direction: column;

    .search {
        display: flex;
        padding: 0px;

        form {
            display: flex;
            flex-wrap: wrap;

            .ivu-form-item {
                flex: none;
            }
        }

        .search-button {
            flex-shrink: 0;
            padding: 3px 5px;

            button {
                margin-left: 5px;
            }
        }
    }

    .grid {
        padding: 5px;
        flex: 1;
    }

    .toolbar {
        display: flex;
        margin-top: 5px;
        padding-top: 5px;
        justify-content: flex-end;
    }
}
</style>
import { storage } from "@/common/storage";
import { getMenuRouter } from "@/router/routers/menuRouter";
import otherPageRouter from "@/router/routers/otherPageRouter";
import routers from "@/router";

export const router = {
    namespaced: true,
    state() {
        return {
            tagList: storage.getPages(),
            menuList: []
        };
    },
    getters: {
        cachedPages(state) {
            return state.tagList.map((item) => item.code);
        },
        menuList(state) {
            return state.menuList;
        }
    },
    mutations: {
        setTagList(state, payload) {
            state.tagList = payload;
            storage.setPages(state.tagList);
        },
        addTag(state, payload) {
            state.tagList.push(payload);
            storage.setPages(state.tagList);
        },
        updateTagList(state, playload) {
            state.tagList.map((item) => {
                if (playload.code == item.code) {
                    item.path = playload.path;
                }
                return item;
            });
            storage.setPages(state.tagList);
        },
        updateMenuList(state, payload) {
            state.menuList = payload;
        },
        updateMenuRouter(state, payload) {
            routers.addRoutes([payload.iframeMenuRouter]);
            routers.addRoutes([...payload.normalRouter]);
            routers.addRoutes([...otherPageRouter]);
        }
    },
    actions: {
        async addDynamicRouter({ commit }) {
            const { menuList, iframeMenuRouter, normalRouter } = await getMenuRouter();

            setTimeout(() => {
                commit('updateMenuList', menuList);
                commit('updateMenuRouter', {
                    iframeMenuRouter,
                    normalRouter
                });
            }, 0);
        }
    }
};
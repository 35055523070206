// 模板
<template>
    <div>
        <!-- 分组下拉 -->
        <Select
            v-if="group == true"
            :disabled="disabled"
            @on-change="selectChange"
            ref="select"
            v-model="model"
            :multiple="multiSelect"
            :style="{ width: width }"
            clearable>
            <OptionGroup v-for="(group, idx) in dataList" :label="group.name" :key="idx">
                <Option
                    v-for="item in group.items"
                    :value="item[valueField]"
                    :key="item[valueField]"
                    :label="getDisplayText(item)">{{ getDisplayText(item) }}</Option>
            </OptionGroup>
        </Select>

        <!-- 单独下拉 -->
        <Select
            v-else
            ref="select"
            v-model="model"
            :multiple="multiSelect"
            :filterable="filterable"
            :placeholder="placeholder"
            :not-found-text="notfoundText"
            @on-open-change="handleExpand"
            @on-select="selectItem"
            @on-change="selectChange"
            :disabled="disabled"
            :style="{ width: width }"
            clearable>
            <Option
                v-for="item in dataList"
                :value="item[valueField]"
                :key="item[valueField]"
                :label="getDisplayText(item)">{{ getDisplayText(item) }}</Option>
        </Select>
    </div>
</template>

// 脚本
<script>
import { request } from '@/network/request';
export default {
    name: 'SvCombobox',
    data: function () {
        return {
            form: null,
            dataList: [],
            planeList: [],
            notfoundText: '无匹配数据',
            isSelectChange: false,
            model: this.value
        };
    },
    props: {
        // 名称
        name: {
            type: String
        },

        // 远程加载数据url
        url: {
            type: String,
            default: ''
        },

        // 下拉宽度显示
        width: {
            type: String,
            default: '100%'
        },

        // 显示字段
        displayField: {
            type: String,
            default: 'name'
        },

        // 值字段
        valueField: {
            type: String,
            default: 'code'
        },

        // 下拉显示格式
        displayFormat: {
            type: String,
            default: '{name}'
        },

        // 加载数据依懒参数
        dependParams: {
            type: String
        },

        // 加载数据依懒参数
        dependParamsKey: {
            type: String,
            default: () => 'parentCode'
        },

        // 清除目标下拉参数
        clearTarget: {
            type: Array,
            default: () => []
        },

        // 数据模型
        value: {
            type: [String, Array, Number]
        },

        // 是否支持多选下拉
        multiSelect: {
            type: Boolean,
            default: false
        },

        // 是否支持分组下拉
        group: {
            type: Boolean,
            default: () => false
        },

        // 禁用当前下拉框
        disabled: {
            type: Boolean,
            default: () => false
        },

        // 是否支持过滤
        filterable: {
            type: Boolean,
            default: () => false
        },

        // 设置mapping值
        mappingFields: {
            type: Array,
            default: () => []
        },

        // 选择框默认文字
        placeholder: {
            type: String,
            default: '请选择'
        },

        // 自动加载
        autoLoad: {
            type: Boolean,
            default: true
        },

        // 展开加载
        expandLoad: {
            type: Boolean,
            default: false
        }
    },
    mounted() {
        if (this.autoLoad) {
            this.loadData();
        }
        if (this.$parent && this.$parent.FormInstance) {
            this.form = this.$parent.FormInstance;
        }
    },
    watch: {
        value(value) {
            this.model = value;

            if (!this.isSelectChange && !this.isEmpty(this.dependParams)) {
                this.handleExpand(true);
            }
            this.isSelectChange = false;
        }
    },
    methods: {
        /**
         * 展开获取依懒参数
         */
        handleExpand(isExpand) {
            if (this.expandLoad && isExpand) {
                this.setSelectData([]);
                if (this.dependParams) {
                    const params = this.getParams();
                    if (params) {
                        this.setLoading();
                        this.loadData(params);
                    }
                } else {
                    this.setLoading();
                    this.loadData();
                }
            }
        },

        /**
         * 选择选项
         */
        selectItem() {
            this.isSelectChange = true;
        },

        /**
         * 下拉框值改变
         */
        selectChange(value) {
            let selectionRecord = this.getSelectionRecord(value);

            // 清除目标字段
            if (this.clearTarget) {
                this.clearTargetFields();
            }

            // 设置映射字段
            if (this.mappingFields.length) {
                this.setMappingField(value);
            }

            // 值为空,清除映射字段
            if (this.isEmpty(value)) {
                this.clearMappingFields();
            }

            this.$emit('input', value);
            this.$emit('onchange', value, selectionRecord, this);
            this.formFieldValidate(value);
        },

        /**
         * 重置form表单验证
         */
        formFieldValidate(value) {
            if (this.form) {
                if (!this.isEmpty(value)) {
                    this.form.validateField(this.$parent.prop);
                }
            }
        },

        /**
         * 获取选择记录
         */
        getSelectionRecord(value) {
            return this.planeList.filter(item => item.code == value);
        },

        /**
         * 加载数据
         */
        loadData(params) {
            if (this.url) {
                request
                    .req({
                        url: this.url,
                        params: params || {},
                        method: 'get'
                    })
                    .then(data => {
                        if (data.success) {
                            const list = data.result || data.list;

                            this.setSelectData(list);
                            this.resetNotFountText();
                            this.setPlaneList(list);
                        } else {
                            this.resetNotFountText();
                            this.$Message.error(data.message || data.msg);
                        }
                    });
            }
        },

        /**
         * 重置NotFountText
         */
        resetNotFountText() {
            this.notfoundText = '无匹配数据';
        },

        /**
         * 展开下拉显示加载
         */
        setLoading() {
            this.notfoundText = '加载中...';
        },

        /**
         * 设置平面数据
         */
        setPlaneList(list) {
            if (this.group) {
                for (let item of list) {
                    for (let item2 of item.items) {
                        this.planeList.push(item2);
                    }
                }
            } else {
                this.planeList = list;
            }
        },

        /**
         * 获取数据
         */
        getParams() {
            let params = {};
            let dependParams = this.dependParams;

            if (this.form && !this.isEmpty(this.form.model[dependParams])) {
                params[this.dependParamsKey] = this.form.model[dependParams];

                return params;
            }

            return null;
        },

        /**
         * 获取值
         */
        getValue() {
            return this.value;
        },

        /**
         * 获取显示文本
         */
        getDisplayText(item) {
            return this.displayFormat
                .replace('{' + this.valueField + '}', item[this.valueField])
                .replace('{' + this.displayField + '}', item[this.displayField]);
        },

        /**
         * 映射关联字段
         */
        setMappingField(value) {
            const selectionRecord = this.dataList.filter(item => item.code == value);

            if (selectionRecord.length) {
                for (let item of this.mappingFields) {
                    this.$set(this.form.model, item.mappingField, selectionRecord[0][item.field]);
                }
            }
        },

        /**
         * 设置下拉选项数据
         */
        setSelectData(data) {
            this.$set(this, 'dataList', data || []);
        },

        /**
         * 清空目标字段
         */
        clearTargetFields() {
            for (let key of this.clearTarget) {
                this.$set(this.form.model, key, '');
            }
        },

        /**
         * 清空目标字段
         */
        clearMappingFields() {
            for (let item of this.mappingFields) {
                this.$set(this.form.model, item.mappingField, '');
            }
        }
    }
};
</script>

<style lang="less">
.ivu-select-group-title {
    font-weight: 700;
}
</style>

<template>
    <div class="vin-input-wrap">
        <span class="text">
            <Input
                v-model="model"
                :placeholder="placeholder"
                :maxlength="maxlength"
                @on-keyup="doEnterSearch"
                @on-change="hanleChange"
                :style="'width:' + boxWidth + 'px'"
                clearable
            />
        </span>
        <Icon class="history-icon" size="18" type="md-book" @click="handleClick" />
    </div>
</template>

<script>
export default {
    props: {
        placeholder: {
            type: String
        },

        labelWidth: {
            type: String,
            default: '80px'
        },
        maxlength: {
            type: [String, Number]
        },
        value: {
            type: [String, Number]
        },
        boxWidth: {
            type: Number,
            default: () => 110
        }
    },
    data() {
        return {
            model: this.value
        };
    },
    watch: {
        value(value) {
            this.model = value;
        }
    },
    methods: {
        hanleChange(e) {
            const { value } = e.target;

            this.$emit('input', value);
            this.$emit('on-change', value);
        },

        doEnterSearch(e) {
            this.$emit('on-keyup', e);
        },

        handleClick() {
            this.$emit('vin-click');
        }
    }
};
</script>

<style lang="less" scoped>
@import '~@/less/sdk/index';
.vin-input-wrap {
    position: relative;
}
.history-icon {
    position: absolute;
    top: 5px;
    right: -20px;
}
</style>

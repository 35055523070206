<template>
    <Card class="sv-card-wrap">
        <p slot="title" v-if="title">{{ title }}</p>
        <p slot="extra" v-show="hasExpand">
            <Icon
                size="18"
                class="arrow-icon"
                @click="handlerIconClick"
                :type="expanded ? 'ios-arrow-down' : 'ios-arrow-up'"
            />
        </p>
        <div v-show="expanded">
            <slot name="body" v-if="hasContent"></slot>
            <div v-else class="no-record">暂无记录</div>
        </div>
        <!-- loading -->
        <Spin size="large" v-if="showLoading" fix></Spin>
    </Card>
</template>

<script>
export default {
    props: {
        title: {
            type: String
        },
        hasExpand: {
            type: Boolean,
            default: () => true
        },
        hasContent: {
            type: Boolean,
            default: () => true
        },
        showLoading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            expanded: true
        };
    },
    methods: {
        handlerIconClick() {
            this.expanded = !this.expanded;
        },
        setExpand(isExpand) {
            this.expanded = isExpand;
        }
    }
};
</script>

<style lang="less" scoped>
.sv-card-wrap {
    position: relative;
    /deep/ .ivu-card-head {
        padding: 7px 8px;
        p {
            font-size: 14px !important;
            color: #4e4e4e !important;
        }
    }
    /deep/ .ivu-card-extra {
        top: 10px;
    }
    .arrow-icon {
        cursor: pointer;
    }
}
</style>

<template>
    <div class="mini-menu-wrap">
        <menu-item v-for="(item, idx) in data" :key="idx" :data="item" />
    </div>
</template>
<script>
import MenuItem from './menu-item';

export default {
    components: {
        MenuItem
    },
    name: 'MiniMenu',
    props: {
        data: {
            type: Array
        }
    },
    data() {
        return {};
    }
};
</script>

<style lang="less">
.mini-menu-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 0px;
}
</style>

import Vue from 'vue';
import {
    Breadcrumb,
    Row,
    Col,
    Card,
    Select,
    Option,
    DatePicker,
    BreadcrumbItem,
    Page,
    Form,
    FormItem,
    Button,
    Table,
    Menu,
    Sider,
    Layout,
    Header,
    Content,
    MenuItem,
    Icon,
    Submenu,
    MenuGroup,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    Tag,
    Input,
    Message,
    Notice,
    Modal,
    Spin,
    RadioGroup,
    Radio,
    BackTop,
    CheckboxGroup,
    Checkbox,
    Upload,
    Tabs,
    TabPane,
    Collapse,
    Panel,
    Switch,
    InputNumber,
    Tree,
    Tooltip,
    Alert,
    AutoComplete,
    Split,
    Poptip,
    Drawer,
    Progress
} from 'view-design';

Vue.component('Row', Row);
Vue.component('Col', Col);
Vue.component('Card', Card);
Vue.component('Select', Select);
Vue.component('Option', Option);
Vue.component('Breadcrumb', Breadcrumb);
Vue.component('BreadcrumbItem', BreadcrumbItem);
Vue.component('Page', Page);
Vue.component('Form', Form);
Vue.component('FormItem', FormItem);
Vue.component('Button', Button);
Vue.component('Table', Table);
Vue.component('Menu', Menu);
Vue.component('MenuItem', MenuItem);
Vue.component('Submenu', Submenu);
Vue.component('MenuGroup', MenuGroup);
Vue.component('Sider', Sider);
Vue.component('Layout', Layout);
Vue.component('Header', Header);
Vue.component('Content', Content);
Vue.component('Icon', Icon);
Vue.component('Dropdown', Dropdown);
Vue.component('DropdownMenu', DropdownMenu);
Vue.component('DropdownItem', DropdownItem);
Vue.component('Tag', Tag);
Vue.component('DatePicker', DatePicker);
Vue.component('Input', Input);
Vue.component('Message', Message);
Vue.component('Modal', Modal);
Vue.component('Spin', Spin);
Vue.component('RadioGroup', RadioGroup);
Vue.component('Radio', Radio);
Vue.component('BackTop', BackTop);
Vue.component('CheckboxGroup', CheckboxGroup);
Vue.component('Checkbox', Checkbox);
Vue.component('Upload', Upload);
Vue.component('Tabs', Tabs);
Vue.component('TabPane', TabPane);
Vue.component('Collapse', Collapse);
Vue.component('Panel', Panel);
Vue.component('i-switch', Switch);
Vue.component('InputNumber', InputNumber);
Vue.component('Tree', Tree);
Vue.component('Tooltip', Tooltip);
Vue.component('Alert', Alert);
Vue.component('AutoComplete', AutoComplete);
Vue.component('Split', Split);
Vue.component('Poptip', Poptip);
Vue.component('Drawer', Drawer);
Vue.component('Progress', Progress);

Vue.prototype.$Notice = Notice;
Vue.prototype.$Message = Message;
Vue.prototype.$Modal = Modal;
Vue.prototype.$Spin = Spin;

Vue.prototype.$Message.config({
    duration: 5
});

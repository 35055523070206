<template>
    <div class="images-wrap" :style="{ height: height + 'px' }">
        <div class="images-body" :key="imgFilename">
            <img :src="prefixSrc + imgFilename" v-default-img="noImgSrc" />
        </div>
        <div class="images-footer" v-if="isEdit">
            <sv-upload :action="uploadUrl" @onUploadSuccess="handlerUploadSuccess" />
        </div>
        <div class="images-note" v-if="isEdit">{{ note }}</div>
    </div>
</template>

<script>
import noImgSrc from '@/assets/images/nopic.png';

export default {
    props: {
        height: {
            type: Number,
            default: 250
        },
        filename: {
            type: String,
            default: ''
        },
        prefixSrc: {
            type: String,
            default: ''
        },
        uploadUrl: {
            type: String,
            default: ''
        },
        note: {
            type: String,
            default: '格式: png, jpg, jpeg, gif, 大小：10MB;'
        },
        model: {
            type: String
        },
        isEdit: {
            type: Boolean,
            default: true
        }
    },
    watch: {
        filename() {
            if (this.filename) {
                this.imgFilename = this.filename;
            }
        },
        model() {
            this.fileId = this.model;
        }
    },

    data() {
        return {
            fileId: '',
            imgFilename: '',
            noImgSrc: noImgSrc,
            form: null
        };
    },
    mounted() {
        if (this.$parent.FormInstance) {
            this.form = this.$parent.FormInstance;
        }
    },
    methods: {
        handlerUploadSuccess(response) {
            if (response.success) {
                this.$set(this, 'fileId', response.result.id);
                this.$set(this, 'imgFilename', response.result.fileUrl);

                if (this.form) {
                    this.$set(this.form.model, this.$parent.prop, response.result.id);
                }
            } else {
                this.$Message.error('上传图失败: ' + response.message);
            }
        }
    }
};
</script>

<style lang="less" scoped>
@import '~@/less/sdk/index';

.images-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    padding: 2px;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-left: 1px solid #e8eaec;

    .images-body {
        flex: 1;
        display: flex;
        width: 100%;
        justify-content: center;
        align-content: center;
        margin-bottom: 5px;
        object-fit: scale-down;

        img {
            width: auto;
            height: auto;
        }
    }

    .images-footer {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .images-note {
        text-align: center;
        color: red;
    }
}
</style>
const defaultImg = {
    inserted: async function(el, binding) {
        const imgUrl = el.src;
        const defaultUrl = binding.value;

        if (imgUrl) {
            const imageSize = await getImgSize(el, imgUrl);

            if (imageSize) {
                el.setAttribute('src', imgUrl);
                el.setAttribute('width', imageSize.width);
                el.setAttribute('height', imageSize.height);
            } else {
                loadDefaultImage(el, defaultUrl);
            }

        } else {
            loadDefaultImage(el, defaultUrl);
        }
    }
}

const loadDefaultImage = async function(el, imgUrl) {
    const imageSize = await getImgSize(el, imgUrl);

    if (imageSize) {
        el.setAttribute('src', imgUrl);
        el.setAttribute('width', imageSize.width);
        el.setAttribute('height', imageSize.height);
    }
}

const getImgSize = function(image, imgUrl) {

    return new Promise((resolve) => {
        const img = new Image();

        img.onload = function() {
            const imageSize = scalingImageSize(image, this);

            resolve(imageSize);
        }
        img.onerror = function() {
            resolve(null);
        }

        img.src = imgUrl;
    });
}

const scalingImageSize = function(image, img) {
    const imgWidth = img.width;
    const imgHeihgt = img.height;
    const outerWidth = image.parentElement.clientWidth;
    const outerHeight = image.parentElement.clientHeight;
    const size = scalingDownCalc(imgWidth, imgHeihgt, outerWidth, outerHeight);

    return {
        width: size.width + 'px',
        height: size.height + 'px'
    };
}

const scalingDownCalc = function(iW, iH, mW, mH) {
    let newW, newH;

    if (iH / iW >= mH / mW) {
        if (iH > mH) {
            newH = mH;
            newW = (iW * mH) / iH;
        } else {
            newW = iW;
            newH = iH;
        }
    } else {
        if (iW > mW) {
            newW = mW;
            newH = (iH * mW) / iW;
        } else {
            newW = iW;
            newH = iH;
        }
    }

    return {
        width: newW,
        height: newH
    };
}

export default defaultImg;
<template>
    <Row>
        <Col span="24">
        <Row class="grid-toolbar" v-if="isShowToolbar">
            <div class="grid-title">{{ listTitle }}</div>
            <div>
                <Button
                    v-for="(item, index) in this.$slots.default[1].children"
                    :key="index"
                    :ref="item.data.attrs.name"
                    :name="item.data.attrs.name"
                    @click.stop="handlerToolbarClick(item.data.attrs.name)"
                    :custom-icon="'ivu-icon ivu-icon-' + item.data.attrs.icon"
                    :loading="buttonLoading[item.data.attrs.name]"
                    class="button"
                    size="default"
                    v-permission="item.data.attrs.permission"
                    :disabled="buttonStatus[item.data.attrs.name]"
                    :title="item.data.attrs.name == 'update' ? '双击行内数据快速打开编辑' : item.data.attrs.title"
                    :directives="item.data.directives">{{ item.data.attrs.text }}</Button>
            </div>

            <slot name="extra"> </slot>
        </Row>
        <Row class="table-wrap">
            <Table
                ref="table"
                highlight-row
                :columns="columns"
                :data="dataList"
                :loading="showLoading"
                :width="tableWidth"
                :height="tableHeight"
                :row-class-name="rowClassName"
                @on-row-click="rowClick"
                @on-row-dblclick="rowDblClick"
                @on-selection-change="selectionChange">
            </Table>
        </Row>
        <Row v-show="isShowPaging" class="page-wrap">
            <Page
                :total="tableDataPage.total"
                class="pull-right margin-top"
                transfer
                show-elevator
                show-sizer
                show-total
                :current="tableDataPage.page"
                :pageSize="tableDataPage.size"
                :pageSizeOpts="tableDataPage.pageSizeOpts"
                @on-change="gotoPage"
                @on-page-size-change="pageSizeOnChange">
                <span class="ivu-page-total">
                    <span v-if="tableDataPage.total">第{{ currentPageNum.start }}-{{ currentPageNum.end }}条</span>
                    总共{{ tableDataPage.total }}条
                </span>
            </Page>
        </Row>
        </Col>
    </Row>
</template>

<script>
import { request } from '@/network/request';
import config from 'config';

export default {
    name: 'SvGrid',

    components: {},

    props: {
        deleteKey: {
            type: String,
            default: () => 'id'
        },
        deleteParamsKey: {
            type: String,
            default: () => 'ids'
        },
        isShowPaging: {
            type: Boolean,
            default: () => true
        },
        isShowToolbar: {
            type: Boolean,
            default: () => true
        },
        width: {
            type: Number,
            default: 0
        },
        height: {
            type: Number,
            default: 0
        },
        dataSource: {
            type: Array
        },
        pageSize: {
            type: Number,
            default: 20
        },
        rownumber: {
            type: Boolean,
            default: false
        },
        multiSelect: {
            type: Boolean,
            default: false
        },
        rowClassName: {
            type: Function
        },

        pageSizeOpts: {
            type: Array,
            default: () => [10, 20, 50, 100]
        },
        onlyEdit: {
            type: Boolean,
            default: false
        },
        needHandleBeforePage: {
            type: Boolean,
            default: false
        },
        handleEventBeforePage: {
            type: Promise
        },
        listTitle: {
            type: String
        },
        marginHeight: {
            type: Number,
            default: 55
        },
        multiSelectFixed: {
            type: Boolean,
            default: false
        },
        autoWidth: {
            type: Boolean,
            default: false
        }
    },

    data() {
        let columns = this.getColumns();

        return {
            showLoading: false,
            isUpdateing: false,
            filterParams: {},
            columns: columns,
            modelIndex: 0,
            dataList: [],
            modifyDataList: [],
            tableWidth: 0,
            tableHeight: 0,
            buttonStatus: this.getButtonStatus(),
            buttonsEnabled: this.getButtonsEnabled(),
            buttonLoading: this.getButtonsLoading(),
            tableDataPage: {
                page: 1,
                size: this.pageSize,
                total: 0,
                pageSizeOpts: this.pageSizeOpts
            },
            currentPageNum: {
                start: 1,
                end: 10
            }
        };
    },

    watch: {
        dataSource: {
            handler() {
                if (this.dataSource && this.dataSource.length) {
                    this.dataList = this.dataSource;
                }
            },
            immediate: true
        }
    },

    mounted() {
        this.$nextTick(() => {
            this.adjustSize();
        });
        window.onresize = () => {
            this.adjustSize();
        };

        this.setButtonsStatus();
    },

    methods: {
        rowClick(record, idx) {
            this.$refs.table.selectAll(false);

            this.$refs.table.objData[idx]._isChecked = true;
            this.$refs.table.objData[idx]._isHighlight = true;

            this.setButtonsStatus();
            this.$emit('rowClick', this.$refs.table.objData[idx]);
        },

        rowDblClick() {
            let record = this.$refs.table.getSelection();
            let status;

            if (this.onlyEdit) {
                return;
            }

            status = 'update';
            this.$emit('rowDblClick', record, status);
        },

        selectionChange(selection) {
            let data = this.$refs.table.objData;

            for (let key in data) {
                let item = data[key];
                if (item._isChecked) {
                    data[key]._isHighlight = true;
                } else {
                    data[key]._isHighlight = false;
                }
            }
            this.setButtonsStatus();
            this.$emit('selectionChange', selection);
        },

        adjustSize() {
            if (!this.autoWidth) {
                if (this.width === 0) {
                    const clientRect = this.$refs.table.$el.getBoundingClientRect();
                    this.tableWidth = window.innerWidth - clientRect.left;
                } else {
                    this.tableWidth = this.width;
                }
            }

            if (this.height === 0) {
                const clientRect = this.$refs.table.$el.getBoundingClientRect();
                this.tableHeight = window.innerHeight - clientRect.top - this.marginHeight;
            } else {
                this.tableHeight = this.height;
            }
        },

        setButtonsStatus() {
            let count = this.getSelectionCount();

            for (let key in this.buttonsEnabled) {
                let btns = this.$refs[key];

                if (btns) {
                    let mode = this.buttonsEnabled[key].mode;

                    if (mode === 1 && count === 1) {
                        this.buttonStatus[key] = false;
                    } else if (mode === 2 && count >= 1) {
                        this.buttonStatus[key] = false;
                    } else if (mode === 3 && count === 1) {
                        let expression = this.buttonsEnabled[key].expression;
                        if (expression) {
                            this.buttonStatus[key] = !this.getExistInSelection(expression);
                        }
                    } else if (mode === 4 && count === 1) {
                        let expression = this.buttonsEnabled[key].expression;
                        if (expression) {
                            this.buttonStatus[key] = !this.getExistInSelectionUn(expression);
                        }
                    } else if (mode === 3 && count >= 1) {
                        let expression = this.buttonsEnabled[key].expression;
                        if (expression) {
                            this.buttonStatus[key] = !this.getExistInSelection(expression);
                        }
                    } else {
                        this.buttonStatus[key] = true;
                    }
                }
            }
        },

        getExistInSelection(expression) {
            let exist = true;
            const objExpression = JSON.parse(expression);
            const records = this.getSelection();

            if (records && records.length) {
                for (let key in objExpression) {
                    if (Array.isArray(objExpression[key])) {
                        if (!objExpression[key].includes(records[0][key])) {
                            exist = false;
                        }
                    } else if (objExpression[key] !== records[0][key]) {
                        exist = false;
                    }
                }
            }

            return exist;
        },

        getExistInSelectionUn(expression) {
            let exist = true;
            const objExpression = JSON.parse(expression);
            const records = this.getSelection();

            if (records && records.length) {
                for (let key in objExpression) {
                    if (objExpression[key] === records[0][key]) {
                        exist = false;
                    }
                }
            }

            return exist;
        },

        getSelectionCount() {
            if (!this.$refs.table) return;

            let count = 0;
            const data = this.$refs.table.objData;

            for (let key in data) {
                if (data[key]._isChecked) {
                    count++;
                }
            }

            return count;
        },

        loadData(filterParams) {
            this.filterParams = filterParams;
            this.tableDataPage.page = 1;
            this.readRecord();
        },

        getColumns() {
            let columns = [];

            const items = this.getTagItems('columns');

            this.addSelectionColumn(columns);

            this.addRownumberColumn(columns);

            items.forEach(item => {
                const { key, attrs } = item.data;
                const { type, dataType, format, dateFormat, tooltip, linkUrl, hidden } = attrs;

                if (key) {
                    attrs['key'] = key;
                }
                if (dataType === 'date') {
                    if (!attrs.flex) {
                        attrs.width = attrs.width || (dateFormat ? '80px' : '125px;');
                    } else {
                        attrs.minWidth = dateFormat ? 80 : 140;
                    }

                    attrs.render = this.getFormatDateRender(attrs);
                }
                if (type === 'textbox') {
                    attrs.render = this.getTextboxRender(attrs);
                }
                if (type === 'numberbox') {
                    attrs.render = this.getNumberboxRender(attrs);
                }
                if (type === 'switch') {
                    attrs.render = this.getSwitchRender(attrs);
                }
                if (type === 'amount') {
                    attrs.align = 'right';
                    attrs.width = attrs.width || '90px';
                    attrs.render = this.getAmountRender(attrs);
                }
                if (typeof type === 'function') {
                    const dType = type();

                    if (dType === 'textbox') {
                        attrs.render = this.getTextboxRender(attrs);
                    }
                    if (dType === 'numberbox') {
                        attrs.render = this.getNumberboxRender(attrs);
                    }
                    if (dType === 'switch') {
                        attrs.render = this.getSwitchRender(attrs);
                    }
                }
                if (!this.isEmpty(linkUrl)) {
                    attrs.render = this.getLinkUrlRender(attrs);
                }
                if (!this.isEmpty(format)) {
                    attrs.render = this.getForamtRender(attrs);
                }
                if (tooltip !== 'false') {
                    attrs.tooltip = true;
                }
                attrs.minWidth = attrs.minWidth || 100;
                if (hidden != true) {
                    attrs.resizable = true;
                    columns.push(attrs);
                }
            });

            return columns;
        },

        addRownumberColumn(columns) {
            if (this.rownumber) {
                columns.push({
                    title: '序号',
                    width: '80px',
                    align: 'center',
                    render: this.getRowNumberRender()
                });
            }
        },

        addSelectionColumn(columns) {
            if (this.multiSelect) {
                columns.push({
                    title: '',
                    type: 'selection',
                    width: '40px',
                    align: 'center',
                    fixed: this.multiSelectFixed ? 'left' : ''
                });
            }
        },

        getRowNumberRender() {
            return (h, { index }) => {
                return h('span', index + (this.tableDataPage.page - 1) * this.tableDataPage.size + 1);
            };
        },

        getFormatDateRender(attrs) {
            const { key, dateFormat } = attrs;

            return (h, { row }) => {
                const formatDate = this.formatDatetime(row[key], dateFormat);
                return h('span', formatDate);
            };
        },

        getTextboxRender(attrs) {
            const { key, maxlength = 5, disabled } = attrs;

            return (h, { row, index }) => {
                const isDisabled = typeof disabled === 'function' ? disabled(row) : disabled;

                const edit = [
                    h('Input', {
                        props: {
                            type: 'text',
                            value: row[key],
                            maxlength: maxlength,
                            disabled: isDisabled
                        },
                        on: {
                            input: value => {
                                this.modifyDataList[index][key] = value;
                            }
                        }
                    })
                ];
                return h('div', [edit]);
            };
        },

        getNumberboxRender(attrs) {
            const { key, max = 999999, min = 0, disabled } = attrs;

            return (h, { row, index }) => {
                const isDisabled = typeof disabled === 'function' ? disabled(row) : disabled;
                const edit = [
                    h('InputNumber', {
                        props: {
                            value: row[key],
                            max: max,
                            min: min,
                            disabled: isDisabled
                        },
                        on: {
                            input: value => {
                                this.modifyDataList[index][key] = value;
                            }
                        }
                    })
                ];
                return h('div', [edit]);
            };
        },

        getSwitchRender(attrs) {
            const { key, disabled } = attrs;

            return (h, { row, index }) => {
                const isDisabled = typeof disabled === 'function' ? disabled(row) : disabled;

                const edit = [
                    h('i-switch', {
                        props: {
                            value: row[key],
                            size: 'small',
                            disabled: isDisabled
                        },
                        on: {
                            'on-change': value => {
                                this.modifyDataList[index][key] = value;
                            }
                        }
                    })
                ];
                return h('div', [edit]);
            };
        },

        getForamtRender(attrs) {
            const { format, defaultValue } = attrs;

            return (h, { row }) => {
                return h('span', this.parseForamt(format, row, defaultValue));
            };
        },

        getAmountRender(attrs) {
            const { key } = attrs;

            return (h, { row }) => {
                return h('span', { class: 'amount-cell' }, this.formatAmount(row[key], 2, attrs.hasSymbol));
            };
        },

        getLinkUrlRender(attrs) {
            const { key, linkUrl } = attrs;
            return (h, params) => {
                const urls = linkUrl.split('?');
                const linkParams = this.getLinkParams(urls[1], params.row);

                return h('a', {
                    domProps: {
                        href: urls[0] + '?' + linkParams,
                        target: '_blank',
                        innerHTML: params.row[key]
                    }
                });
            };
        },

        getLinkParams(params, row) {
            const items = params.split('&');
            const urlParams = [];

            items.forEach(item => {
                const keys = item.split('=');

                if (keys.length > 1) {
                    if (keys[1].indexOf('{') > -1 && keys[1].indexOf('}') > -1) {
                        const key = keys[1].replace('{', '').replace('}', '');
                        const value = row[key];

                        urlParams.push(`${keys[0]}=${value}`);
                    } else {
                        const value = keys[1];

                        urlParams.push(`${keys[0]}=${value}`);
                    }
                } else {
                    const value = row[keys[0]];

                    urlParams.push(`${keys[0]}=${value}`);
                }
            });

            return urlParams.join('&');
        },

        getUrlsParmas(name, key) {
            let params = '';
            const items = this.getTagItems('urls');

            items.forEach(item => {
                if (item.data.attrs.name === name) {
                    params = item.data.attrs[key];
                }
            });

            return params;
        },

        getButtonStatus() {
            let buttonStatus = {};
            const buttonsEnabled = this.getButtonsEnabled();

            for (let key in buttonsEnabled) {
                buttonStatus[key] = buttonsEnabled[key].enabled;
            }

            return buttonStatus;
        },

        getButtonsLoading() {
            let buttonsLoading = {};
            const items = this.getTagItems('toolbars');

            items.forEach(item => {
                let name = item.data.attrs.name;
                buttonsLoading[name] = false;
            });

            return buttonsLoading;
        },

        getButtonsEnabled() {
            let items = this.getTagItems('toolbars');
            let itemButtonsEnabled = {};
            let buttonsEnabled = {
                update: {
                    mode: 1,
                    enabled: false
                },
                delete: {
                    mode: 2,
                    enabled: false
                },
                download: {
                    mode: 1,
                    enabled: false
                }
            };

            items.forEach(item => {
                let name = item.data.attrs.name;
                let enableMode = item.data.attrs.enableMode;
                let enableExpression = item.data.attrs.enableExpression;

                if (enableMode) {
                    itemButtonsEnabled[name] = {
                        mode: parseInt(enableMode),
                        enabled: false,
                        expression: enableExpression
                    };
                }
            });

            return Object.assign(buttonsEnabled, itemButtonsEnabled);
        },

        getTagItems(tag) {
            let items = [];
            let slots = this.$slots.default;

            slots.forEach(item => {
                if (item.tag === tag) {
                    items = item.children || [];
                }
            });

            return items;
        },

        gotoPage(page) {
            if (this.needHandleBeforePage) {
                this.handleEventBeforePage();
            }
            this.tableDataPage.page = page;
            this.currentPageNum = {
                start: (this.tableDataPage.page - 1) * this.tableDataPage.size + 1,
                end: this.tableDataPage.page * this.tableDataPage.size
            };
            this.readRecord();
        },

        pageSizeOnChange(size) {
            this.tableDataPage.page = 1;
            this.tableDataPage.size = size;
            this.currentPageNum = {
                start: (this.tableDataPage.page - 1) * size + 1,
                end: this.tableDataPage.page * size
            };
            this.readRecord();
        },

        handlerToolbarClick(name) {
            const record = this.getSelection();

            switch (name) {
                case 'create':
                    this.$emit('create');
                    break;
                case 'update':
                    this.$emit('update', record[0]);
                    break;
                case 'import':
                    this.$emit('import');
                    break;
                case 'export':
                    this.$emit('export');
                    break;
                case 'delete':
                    this.$Modal.confirm({
                        title: '确认删除当前记录吗?',
                        onOk: async () => {
                            this.deleteRecord();
                        }
                    });
                    break;
                default:
                    this.$emit('onToolbarClick', name, record);
                    break;
            }
        },

        readRecord() {
            const url = this.getUrlsParmas('read', 'url');
            let params = this.getReadParams(this.filterParams);

            this.showLoading = true;

            request
                .get(url, params)
                .then(data => {
                    this.readRecordSuccess(data);
                })
                .finally(() => {
                    this.showLoading = false;
                });
        },

        readRecordSuccess(data) {
            if (data.success) {
                this.dataList = data.list || data.result.list;
                this.modifyDataList = this.dataList.map(item => ({ ...item }));
                this.tableDataPage.total = parseInt(data.total || data.result.total);
                this.$refs.table.selectAll(false);
                this.setButtonsStatus();
            } else {
                this.tableDataPage.total = 0;
                this.dataList = [];
                this.$Message.error('查询失败: ' + data.message);
            }

            this.$emit('dataLoaded', this.dataList, this.tableDataPage);
        },

        createRecord(params, status, callback) {
            let url;
            switch (status) {
                case 'publish':
                    url = this.getUrlsParmas('publish', 'url');
                    break;
                case 'online':
                    url = this.getUrlsParmas('online', 'url');
                    break;
                case 'apply':
                    url = this.getUrlsParmas('apply', 'url');
                    break;
                default:
                    url = this.getUrlsParmas('create', 'url');
                    break;
            }

            request
                .post(url, params)
                .then(data => {
                    this.createRecordSuccess(data, status, callback);
                })
                .catch(() => { });
        },

        createRecordSuccess(data, status, callback) {
            if (data.success) {
                if (status === 'publish') {
                    this.$Message.success('发布成功');
                } else {
                    this.$Message.success('创建成功');
                }
                this.readRecord();
                this.$emit('created');
            } else {
                if (status === 'publish') {
                    this.$Message.error('发布失败: ' + data.message);
                } else {
                    this.$Message.error('创建失败: ' + data.message);
                }
            }

            if (typeof callback === 'function') {
                callback(data.success);
            }
        },

        updateRecord(params, status, callback) {
            let url;

            switch (status) {
                case 'iterate':
                    url = this.getUrlsParmas('iterate', 'url');
                    break;
                case 'iterateApply':
                    url = this.getUrlsParmas('iterateApply', 'url');
                    break;
                case 'apply':
                    url = this.getUrlsParmas('apply', 'url');
                    break;
                case 'send':
                    url = this.getUrlsParmas('send', 'url');
                    break;
                case 'online':
                    url = this.getUrlsParmas('online', 'url');
                    break;
                case 'publish':
                    url = this.getUrlsParmas('publishUpdate', 'url');
                    break;
                default:
                    url = this.getUrlsParmas('update', 'url');
                    break;
            }

            request
                .post(url, params)
                .then(data => {
                    this.updateRecordSuccess(data, status, callback);
                })
                .catch(() => { });
        },

        updateRecordSuccess(data, status, callback) {
            if (data.success) {
                this.$Message.success('更新成功');
                this.readRecord();
                this.$emit('updated');
            } else {
                this.$Message.error('更新失败：' + data.message);
            }

            if (typeof callback === 'function') {
                callback(data.success);
            }
        },

        deleteRecord() {
            const params = this.getDeleteParams();
            const url = this.getUrlsParmas('delete', 'url');

            request
                .post(url, params)
                .then(data => {
                    this.deleteRecordSuccess(data);
                })
                .catch(() => { });
        },

        deleteRecordSuccess(data) {
            if (data.success) {
                this.readRecord();
                this.$Message.success('删除成功');
            } else {
                this.$Message.error('删除失败: ' + data.message);
            }
        },

        exportRecord(filterParams) {
            const url = this.getUrlsParmas('export', 'url');
            const filename = this.getUrlsParmas('export', 'filename');
            const params = this.getExportParams(filterParams, filename);

            this.buttonLoading['export'] = true;
            request
                .get(url, params)
                .then(data => {
                    this.handlerExportRecordSuccess(data);
                    this.buttonLoading['export'] = false;
                })
                .catch(() => {
                    this.buttonLoading['export'] = false;
                });
        },

        handlerExportRecordSuccess(data) {
            if (data.success) {
                window.open(config.resURL + data.result.downloadUrl, '_blank');
            } else {
                this.$Message.error('导出失败: ' + data.message);
            }
        },

        getReadParams(filterParams) {
            const pageSize = this.tableDataPage.size;
            const curPageIndex = this.tableDataPage.page || 1;
            const queryParams = this.getPageQueryParams(filterParams, curPageIndex, pageSize);

            return queryParams;
        },

        getDeleteParams() {
            let params = [];
            let result = {};
            const records = this.$refs.table.getSelection();

            records.forEach(item => {
                params.push(item[this.deleteKey]);
            });
            result[this.deleteParamsKey] = params;

            return result;
        },

        getExportParams(filterParams, moduleName) {
            const queryParams = this.formatQueryParams(filterParams, 1, 99999);
            const exportParmas = this.getExportModuleMeta(moduleName);

            return this.formatParams({ ...queryParams, moduleMeta: exportParmas });
        },

        formatParams(params) {
            return 'args=' + encodeURIComponent(JSON.stringify(params));
        },

        getExportModuleMeta(moduleName) {
            const columns = [];
            const items = this.getTagItems('columns');

            items.forEach(item => {
                const field = item.data.key;
                const text = item.data.attrs.title;
                const width = item.data.attrs.width ? Number(item.data.attrs.width.replace('px', '')) : 50;
                const needExport = item.data.attrs.notExport !== 'true';

                if (field && needExport) {
                    columns.push({
                        field: field,
                        text: text,
                        width: width
                    });
                }
            });

            return {
                moduleName: moduleName,
                columns: columns
            };
        },

        getSelection() {
            return this.$refs.table.getSelection();
        },

        getDataSource() {
            return this.dataList;
        },

        getModifyDataList() {
            return this.modifyDataList;
        },

        setDefaultParams(params) {
            this.filterParams = params;
        }
    }
};
</script>
<style lang="less" scoped>
/deep/ .amount-cell {
    font-weight: 700;
    color: #ff8834;
}

.grid-toolbar {
    padding: 8px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid @border-color;

    &>div:first-child {
        margin-right: auto;
    }

    .button {
        margin-right: 5px;
        border-color: @back-15;
        color: @back-65;
    }

    .iconfont {
        font-size: 16px !important;
    }

    .ivu-icon {
        vertical-align: 0cm !important;
    }

    .grid-title {
        font-size: 16px;
        color: @back-85;
        line-height: 32px;
        font-weight: 600;
        margin-left: 8px;
    }
}

.table-wrap {
    padding: 8px;
}

.page-wrap {
    justify-content: flex-end;
    padding: 8px;
}
</style>

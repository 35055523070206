<template>
    <span class="sv-icon-button">
        <i :class="['iconfont', icon]" />
    </span>
</template>

<script>
export default {
    props: ['icon']
};
</script>

<style lang="less" scoped>
.sv-icon-button {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 30px;
    height: 30px;
    justify-content: center;
    color: @primary-color;
}
</style>
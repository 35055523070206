<template>
  <div class="sv-display-field" :style="{ width: width }">
    <span class="label" :style="{ width: labelWidth }">{{ label }}: </span>
    <!-- 1) 金额 -->
    <span v-if="type === 'amount'" class="amount">{{ formatAmount(text) }}</span>
    <!-- 2) 不换行 -->
    <span v-else-if="nowrap" class="ellipsis-text" :title="text">{{ text }}</span>
    <!-- 3) 正常 -->
    <span v-else class="text">{{ text }}</span>
  </div>
</template>

<script>
export default {
    props: {
        label: {
            type: String
        },
        labelWidth: {
            type: String,
            default: '100px'
        },
        width: {
            type: String
        },
        type: {
            type: String
        },
        text: {
            type: [String, Number]
        },
        nowrap: {
            type: Boolean,
            default: false
        }
    }
};
</script>

<style lang="less" scoped>
@import '~@/less/sdk/index';

.sv-display-field {
    display: flex;
    font-size: 14px;
    padding: 4px 0;
    line-height: 18px;
    .label {
        display: inline-block;
        color: #515a6e;
        font-weight: 700;
        text-align: right;
        flex-shrink: 0;
        margin-right: 3px;
    }
    .amount {
        font-weight: 700;
        color: #ff8834;
    }
    .text {
        flex: 1;
        word-break: break-all;
        color: @font-color;
    }
    .ellipsis-text {
        display: inline-block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}
</style>

import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import './initComps';
import directives from '@/directives';

// 开发环境引用mock
// if (process.env.NODE_ENV === 'development') require('./mock');

Vue.use(directives);
Vue.config.productionTip = false;
Vue.prototype.$eventBus = new Vue();

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');

<template>
    <div class="form-wrap">
        <Form
            ref="form"
            :model="dataModel"
            :rules="validateModel"
            :label-width="labelWidth"
            :show-message="false"
            @submit.native.prevent>
            <Row v-for="(item1, idx) in getSlotsItems()" :key="'row' + idx">
                <Col
                    v-for="(item2, idx) in item1.children || []"
                    :key="'col' + idx"
                    :span="item2.data.attrs.span || 24"
                    :style="item2.data.attrs.colStyle"
                    :class-name="'col-wrap' + ' ' + item2.data.attrs.colCls">
                <div v-if="!isEmpty(item1.data.attrs.title)" class="group-title">
                    <div class="group-name">{{ item1.data.attrs.title }}</div>
                    <div class="group-line" />
                </div>
                <Row>
                    <Col
                        v-for="(item3, idx) in item2.children"
                        :key="'cmp-' + idx"
                        :set="(attrs = (item3.data && item3.data.attrs) || {})"
                        :span="item3.data.attrs.span || column">
                    <!-- 表单元素 -->
                    <FormItem
                        v-show="!getHidden(attrs)"
                        :label="attrs.label ? attrs.label + ' : ' : ''"
                        :prop="item3.data.attrs.name"
                        :class="attrs.type === 'image' && 'image-class'"
                        :key="'fi' + idx">
                        <!-- 纯文本显示 -->
                        <span v-if="attrs.type === 'displayfield' || isEmpty(attrs.type)" class="displayfield">
                            {{ dataModel[item3.data.attrs.name] }}
                            <a
                                v-if="attrs.historyTip"
                                :title="attrs.historyTip"
                                @click="handleIconClick(item3.data.attrs.name)">查看</a>
                        </span>

                        <!-- 文本框 -->
                        <Input
                            v-if="attrs.type === 'textbox'"
                            v-model="dataModel[item3.data.attrs.name]"
                            :maxlength="getMaxLength(attrs)"
                            :style="'width:' + (item3.data.attrs.boxWidth || textboxWidth)"
                            :hidden="true"
                            :disabled="attrs.disabled === 'false' ? false : disableAll ? true : false" />

                        <!-- 数字框 -->
                        <InputNumber
                            v-if="attrs.type === 'numberbox'"
                            v-model="dataModel[item3.data.attrs.name]"
                            :max="attrs.max || 99999"
                            :min="attrs.min || 0"
                            :active-change="true"
                            :disabled="attrs.disabled === 'false' ? false : disableAll ? true : false"
                            @on-change="handleInputChange(item3.data.attrs.name, $event, 'change')"
                            @keyup.native="handleInputChange(item3.data.attrs.name, $event, 'keyup')"
                            style="width: 100%" />

                        <!-- 多行文本框 -->
                        <Input
                            v-if="attrs.type === 'textarea'"
                            type="textarea"
                            v-model="dataModel[item3.data.attrs.name]"
                            :autosize="{ minRows: attrs.rows || 3, maxRows: attrs.rows || 3 }"
                            :style="'width:' + (item3.data.attrs.boxWidth || textboxWidth)"
                            :maxlength="getMaxLength(attrs)"
                            :disabled="attrs.disabled === 'false' ? false : disableAll ? true : false" />

                        <!-- 列表选择器 -->
                        <SvSelectorField
                            v-if="attrs.type === 'selectorfield'"
                            :model="dataModel[item3.data.attrs.name]"
                            :title="attrs.title"
                            :url="attrs.url"
                            :searchFields="attrs.searchFields"
                            :gridColumns="attrs.gridColumns"
                            :disabled="attrs.disabled === 'false' ? false : disableAll ? true : false"
                            :labelWidth="attrs.labelWidth"
                            :valueField="attrs.valueField"
                            :keyName="attrs.keyName"
                            :multiSelect="attrs.multiSelect"
                            :mappingFields="attrs.mappingFields"
                            :extraParams="attrs.extraParams"
                            width="100%" />

                        <!-- 4.日历控件 -->
                        <DatePicker
                            v-if="attrs.type === 'datebox'"
                            v-model="dataModel[item3.data.attrs.name]"
                            placement="bottom-end"
                            dataType="date"
                            :style="'width:' + textboxWidth"
                            :disabled="attrs.disabled === 'false' ? false : disableAll ? true : false" />

                        <!-- 5.下拉框 -->
                        <SvCombobox
                            v-if="attrs.type === 'combobox'"
                            :name="item3.data.attrs.name"
                            v-model="dataModel[item3.data.attrs.name]"
                            :url="attrs.url"
                            :displayFormat="attrs.displayFormat"
                            :dependParams="attrs.dependParams"
                            :filterable="attrs.filterable"
                            :targetFields="attrs.targetFields"
                            :multiSelect="attrs.multiSelect"
                            :mappingFields="attrs.mappingFields"
                            :width="item3.data.attrs.boxWidth || textboxWidth"
                            :group="attrs.group"
                            :disabled="attrs.disabled === 'false' ? false : disableAll ? true : false"
                            @onchange="hanlerComboboxChange" />

                        <!-- 6.link button -->
                        <div
                            v-if="item3.data.attrs.type === 'link'"
                            :class="{ text: true, link: true }"
                            @click="handleIconClick(item3.data.attrs.name)">
                            {{ item3.data.attrs.text }}
                        </div>

                        <!-- 7.图片 -->
                        <div v-if="item3.data.attrs.type === 'image'">
                            <sv-image
                                v-for="item in dataModel[item3.data.attrs.name]"
                                :key="item.path"
                                :width="100"
                                :height="100"
                                :src="item.path"
                                :isShowDownload="false"></sv-image>
                        </div>

                        <slot
                            :name="item3.data.attrs.name"
                            :data="dataModel[item3.data.attrs.name]">
                        </slot>

                    </FormItem>
                    </Col>
                </Row>
                </Col>
            </Row>
            <slot name="extra"></slot>
        </Form>

        <div class="footer" v-if="isShowFooter">
            <Button type="primary" @click="doSubmit()" :loading="loading">保存</Button>
        </div>
    </div>
</template>

<script>
const COMPS_TYPES = ['textbox', 'numberbox', 'combobox', 'textarea', 'datebox', 'displayfield', 'selectorfield'];

export default {
    props: {
        title: {
            type: String,
            default: ''
        },
        column: {
            type: Number,
            default: () => 6
        },
        labelWidth: {
            type: Number,
            default: () => 150
        },
        textboxWidth: {
            type: String,
            default: () => '100%'
        },
        isShowFooter: {
            type: Boolean,
            default: () => false
        },
        disableAll: {
            type: Boolean,
            default: () => false
        }
    },
    data() {
        return {
            dataModel: this.getDataModel(),
            validateModel: this.getValidateModel(),
            loading: false
        };
    },

    methods: {
        hanlerComboboxChange(value, record, target) {
            this.$emit('comboboxchange', value, record, target);
        },

        handleInputChange(name, e, eventName) {
            const value = eventName === 'keyup' ? e.target.value : this.dataModel[name];

            this.$emit('onInputChange', name, value);
        },

        getSlotsItems() {
            let items = this.$slots.default.filter(item => {
                if (item.tag) return item;
            });

            return items;
        },
        async doSubmit() {
            const isValid = await this.$refs.form.validate();

            if (isValid) {
                const params = this.getParams();
                this.$emit('onSubmit', params);
            } else {
                this.$Message.error('表单必填项不能为空!');
            }
        },

        async doValidate() {
            return await this.$refs.form.validate();
        },

        setRecord(record) {
            for (let key in this.dataModel) {
                const item = this.findFormItems(key);
                const dataType = this.getDataType(item);
                const val = record[key];

                if (dataType === 'date') {
                    this.dataModel[key] = this.formatDatetime(val, item.format);
                } else if (dataType === 'number') {
                    this.dataModel[key] = this.isEmpty(val) ? 0 : Number(val);
                } else {
                    if (Array.isArray(val)) {
                        this.dataModel[key] = val.join(',');
                    } else {
                        this.dataModel[key] = val;
                    }
                }
            }
        },

        setValues(values) {
            for (let key in values) {
                this.dataModel[key] = values[key];
            }
        },

        setFieldValue(name, value) {
            this.dataModel[name] = value;
        },

        getDataModel() {
            let dataModel = {};
            const items = this.getFormItems(this.$slots.default);

            items.forEach(item => {
                const { name, value } = item.data.attrs;
                const dataType = this.getDataType(item.data.attrs);

                if (dataType === 'number') {
                    dataModel[name] = this.isEmpty(value) ? 0 : value;
                } else {
                    dataModel[name] = this.isEmpty(value) ? '' : value;
                }
            });

            return dataModel;
        },

        getValidateModel() {
            let validateModel = {};
            const items = this.getFormItems(this.$slots.default);

            items.forEach(item => {
                let attrs = item.data && item.data.attrs;
                const name = attrs.name;
                const required = attrs.required === 'true';

                if (required) {
                    validateModel[name] = [
                        {
                            required: true,
                            message: ' ',
                            trigger: 'blur',
                            type: this.getDataType(attrs)
                        }
                    ];
                }
            });

            return validateModel;
        },

        getDataType(attrs) {
            const { type, dataType } = attrs;

            if (type === 'datebox' || dataType === 'date') {
                return 'date';
            } else if (type === 'numberbox' || dataType === 'number') {
                return 'number';
            } else {
                return 'string';
            }
        },

        getHidden(attrs) {
            const hidden = attrs.hidden || '';

            return hidden === 'true';
        },

        getFormItems(data) {
            let nodes = [];

            data.forEach(item => {
                let subNodes = [];
                let type = item.data && item.data.attrs.type;

                if (item.children && item.children.length) {
                    subNodes = this.getFormItems(item.children);
                }
                if (subNodes.length > 0) {
                    nodes = nodes.concat(subNodes);
                } else if (COMPS_TYPES.indexOf(type) > -1) {
                    nodes.push(item);
                }
            });

            return nodes;
        },

        findFormItems(name) {
            let attrs = null;
            let items = this.getFormItems(this.$slots.default);

            items.forEach(item => {
                if ((item.data && item.data.attrs.name) === name) {
                    attrs = item.data.attrs;
                }
            });

            return attrs;
        },

        getParams() {
            const params = {};
            const fields = this.getFormItems(this.$slots.default);

            fields.forEach(item => {
                const attrs = item.data && item.data.attrs;
                const name = attrs.name;
                const propsData = this.getPropsData(name);

                if (propsData) {
                    const value = this.isEmpty(propsData.value) ? null : propsData.value;

                    if (attrs.noSubmit !== 'true') {
                        if (attrs.type === 'datebox') {
                            params[name] = this.formatDatetime(value, attrs.format || 'YYYY-MM-DD');
                        } else if (attrs.type === 'selectorfield') {
                            if (propsData.multiSelect) {
                                params[name] =
                                    (propsData.model && propsData.model.length == 0) || !propsData.model
                                        ? []
                                        : (propsData.model || '').split(',');
                            } else {
                                params[name] = propsData.model;
                            }
                        } else {
                            params[name] = value;
                        }
                    }
                }
            });

            return params;
        },

        getPropsData(name) {
            const fields = this.$refs.form.fields;

            for (let i = 0; i < fields.length; i++) {
                let item = fields[i];

                if (item.$children.length) {
                    let opts = item.$children[0].$options;
                    if (item.prop === name) {
                        return opts.propsData;
                    }
                }
            }

            return null;
        },

        getMaxLength(attrs) {
            return this.isEmpty(attrs.maxlength) ? 200 : attrs.maxlength;
        },

        handleIconClick(name) {
            this.$emit('onIconClick', name);
        }
    }
};
</script>

<style lang="less" scoped>
@import '~@/less/sdk/index';

.form-wrap {
    padding: 2px;

    .displayfield {
        display: inline-block;
        word-break: break-all;
        color: @back-65;
        height: 24px;
        line-height: 24px;
    }

    .has-history {
        cursor: pointer;
        font-size: 18px;
    }

    .footer {
        text-align: center;
        margin-top: 15px;
    }

    .text {
        color: #313131;
        font-size: 12px;

        &.warn {
            color: red;
        }
    }

    .link {
        cursor: pointer;
        user-select: none;
        color: @primary-color;
        width: 100%;
    }

    .group-title {
        .group-line {
            border-top: 1px solid #ddd;
            margin: 10px 0;
        }
    }
}

/deep/.ivu-form-item {
    height: 24px;
}
</style>
